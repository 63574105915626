import React, { useCallback, useEffect, useState } from "react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IReportingSelectedFiltersProps } from "@helpers/uiInterfaces/employeeFiles";
import { getCheckboxSelectButtonConfigurations } from "@common/checkbox-select/utilities";
import {
  parseCredJobSelectControlsData,
  parseSelectContactsControlsData,
} from "@utilities/index";
import { getOrganizationContactsByType } from "@services/organizations/contact";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/index";

const ReportingSelectedFilters: React.FC<IReportingSelectedFiltersProps> = (
  props
) => {
  const { filters, onFilterChange } = props;
  const dispatch = useDispatch<any>();
  const [credentialerOptions, setCredentialerOptions] = useState([]);
  const [analystOptions, setAnalystOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);

  const jobCredStatus = useAppSelector(
    (state) => state.trackingReport.jobCredStatus
  );

  const loadDependencies = useCallback(async () => {
    const credentialerRes = await dispatch(getOrganizationContactsByType(9));
    if (credentialerRes.status === 200)
      setCredentialerOptions(credentialerRes.data);

    const analystRes = await dispatch(getOrganizationContactsByType(10));
    if (analystRes.status === 200) setAnalystOptions(analystRes.data);

    const managerRes = await dispatch(getOrganizationContactsByType(11));
    if (managerRes.status === 200) setManagerOptions(managerRes.data);
  }, [dispatch]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  function handleFilterChange(name: string) {
    let data: any = { ...filters };
    if (name === "employeeType" || name === "orgStatus") {
      data[name] = "All";
    } else if (
      name === "credOrganizationId" ||
      name === "processingType" ||
      name === "requestCredType"
    ) {
      data[name] = [];
    } else if (
      name === "associatedCredentialer" ||
      name === "assignedDataAnalyst" ||
      name === "assignedManager" ||
      name === "credJobStatus"
    ) {
      data[name] = 0;
    } else {
      data[name] = "";
    }
    onFilterChange(data);
  }

  function renderRemoveFilterControl(name: string) {
    const removeFilterControlAttributes = {
      icon: faCircleXmark,
      className: "remove-filter-control",
      onClick() {
        handleFilterChange(name);
      },
    };

    return <FontAwesomeIcon {...removeFilterControlAttributes} />;
  }

  function renderFilterChip(label: string, name: string, value: string) {
    const filterChipLabelAttributes = {
      title: `${label}: ${value}`,
    };

    return (
      <>
        {value !== "All" && (
          <div className="filter-chip">
            <label {...filterChipLabelAttributes}>
              {label}: {value}
            </label>
            {renderRemoveFilterControl(name)}
          </div>
        )}
      </>
    );
  }

  function renderSearchNameFilter() {
    if (filters.search.trim() === "") {
      return;
    }

    return renderFilterChip("Name", "search", filters.search);
  }

  function renderEmployeeTypeFilter() {
    if (!filters.employeeType) {
      return;
    }

    let employeeTypeValue = "All";

    if (filters.employeeType === "1") {
      employeeTypeValue = "Active";
    } else if (filters.employeeType === "0") {
      employeeTypeValue = "Inactive";
    }

    return renderFilterChip("Provider Type", "employeeType", employeeTypeValue);
  }

  function renderAssociatedCredentialerFilter() {
    if (!filters.associatedCredentialer) {
      return;
    }
    const value =
      parseSelectContactsControlsData(credentialerOptions).find(
        (data) => data.value === +filters.associatedCredentialer
      )?.label || "";
    return renderFilterChip(
      "Associated Credentialer",
      "associatedCredentialer",
      value
    );
  }

  function renderAssignedDataAnalystFilter() {
    if (!filters.assignedDataAnalyst) {
      return;
    }
    const value =
      parseSelectContactsControlsData(analystOptions).find(
        (data) => data.value === +filters.assignedDataAnalyst
      )?.label || "";
    return renderFilterChip(
      "Assigned Data Analyst",
      "assignedDataAnalyst",
      value
    );
  }

  function renderAssignedManagerFilter() {
    if (!filters.assignedManager) {
      return;
    }
    const value =
      parseSelectContactsControlsData(managerOptions).find(
        (data) => data.value === +filters.assignedManager
      )?.label || "";
    return renderFilterChip("Assigned Manager", "assignedManager", value);
  }

  function renderRequestCredTypeFilter() {
    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.requestCredType);

    let reqCredTypeFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      reqCredTypeFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Requested Cred Type",
      "requestCredType",
      reqCredTypeFilterLabel
    );
  }

  function renderProcessignTypeFilter() {
    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.processingType);

    let credProcessignTypeFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      credProcessignTypeFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Processing Type",
      "processingType",
      credProcessignTypeFilterLabel
    );
  }

  function renderCredStatusFilter() {
    if (!filters.credJobStatus) {
      return;
    }
    const value =
      parseCredJobSelectControlsData(jobCredStatus).find(
        (data) => data.value === +filters.credJobStatus
      )?.label || "";
    return renderFilterChip("Cred/Instance Status", "credJobStatus", value);
  }

  function renderCredOrgFilter() {
    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.credOrganizationId);

    let credOrgFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      credOrgFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Cred Organization",
      "credOrganizationId",
      credOrgFilterLabel
    );
  }

  function renderStartDateFilter() {
    if (!filters.startDate) {
      return;
    }

    return renderFilterChip("Start Date", "startDate", filters.startDate);
  }

  function renderEndDateFilter() {
    if (!filters.endDate) {
      return;
    }

    return renderFilterChip("End Date", "endDate", filters.endDate);
  }

  function renderOrgCreatedFromDateFilter() {
    if (!filters.orgCreatedFromDate) {
      return;
    }

    return renderFilterChip(
      "Org Created From Date",
      "orgCreatedFromDate",
      filters.orgCreatedFromDate
    );
  }

  function renderOrgCreatedToDateFilter() {
    if (!filters.orgCreatedToDate) {
      return;
    }

    return renderFilterChip(
      "Org Created To Date",
      "orgCreatedToDate",
      filters.orgCreatedToDate
    );
  }

  function renderOrgTerminationFromDateFilter() {
    if (!filters.orgTerminationFromDate) {
      return;
    }

    return renderFilterChip(
      "Org Termination From Date",
      "orgTerminationFromDate",
      filters.orgTerminationFromDate
    );
  }

  function renderOrgTerminationToDateFilter() {
    if (!filters.orgTerminationToDate) {
      return;
    }

    return renderFilterChip(
      "Org Termination To Date",
      "orgTerminationToDate",
      filters.orgTerminationToDate
    );
  }

  function renderOrgStatusFilter() {
    if (!filters.orgStatus) {
      return;
    }

    let orgStatusValue = "All";

    if (filters.orgStatus === "1") {
      orgStatusValue = "Active";
    } else if (filters.orgStatus === "0") {
      orgStatusValue = "Inactive";
    }

    return renderFilterChip("Org Status", "orgStatus", orgStatusValue);
  }

  function renderLogCreationFromDateFilter() {
    if (!filters.logCreationFromDate) {
      return;
    }

    return renderFilterChip(
      "Log Creation From Date",
      "logCreationFromDate",
      filters.logCreationFromDate
    );
  }

  function renderLogCreationToDateFilter() {
    if (!filters.logCreationToDate) {
      return;
    }

    return renderFilterChip(
      "Log Creation To Date",
      "logCreationToDate",
      filters.logCreationToDate
    );
  }

  return (
    <div className="selected-filters">
      {renderEmployeeTypeFilter()}
      {renderSearchNameFilter()}
      {renderAssociatedCredentialerFilter()}
      {renderAssignedDataAnalystFilter()}
      {renderAssignedManagerFilter()}
      {renderRequestCredTypeFilter()}
      {renderProcessignTypeFilter()}
      {renderCredStatusFilter()}
      {renderCredOrgFilter()}
      {renderStartDateFilter()}
      {renderEndDateFilter()}
      {renderOrgCreatedFromDateFilter()}
      {renderOrgCreatedToDateFilter()}
      {renderOrgTerminationFromDateFilter()}
      {renderOrgTerminationToDateFilter()}
      {renderOrgStatusFilter()}
      {renderLogCreationFromDateFilter()}
      {renderLogCreationToDateFilter()}
    </div>
  );
};

export default ReportingSelectedFilters;
