import React, { useCallback, useEffect } from "react";
import BootstrapSpinner from "react-bootstrap/Spinner";
import { useAppSelector } from "@hooks/index";
import ProfileAvatar from "@common/profile-avatar/ProfileAvatar";
import { checkObjectIsEmpty } from "@utilities/index";
import { getUserFullName, renderPrefix } from "./utilities";
import styles from "./Credentialing.module.scss";
import { useDispatch } from "react-redux";
import { getEmployeeData } from "@services/employeeFiles";
import { getPersonalInfo } from "@services/credentialing-profile/perProfInfoTab/PersonalInfo";

const CredUserProfile = (reloadComponent?: any) => {
  const dispatch = useDispatch<any>();
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const personalInfoState = useAppSelector(
    (state) => state.employeeFiles.employee
  );
  const personalInfoData = personalInfoState.employeeData;
  const isParentProfile = useAppSelector(
    (state) => state.credentialProfile.credentialP.isParentProfile
  );

  const loadEmployeeData = useCallback(async () => {
    await dispatch(
      getEmployeeData(
        isParentProfile ? userRecord.parentHRMRUserId : userRecord.hrmrUserId
      )
    );
    if (personalInfoData.firstName === "")
      await dispatch(
        getPersonalInfo(
          isParentProfile ? userRecord.parentHRMRUserId : userRecord.hrmrUserId
        )
      );
  }, [
    dispatch,
    userRecord?.hrmrUserId,
    personalInfoData?.firstName,
    userRecord?.parentHRMRUserId,
    isParentProfile,
  ]);

  useEffect(() => {
    if (isParentProfile ? userRecord.parentHRMRUserId : userRecord.hrmrUserId)
      loadEmployeeData();
  }, [
    userRecord?.hrmrUserId,
    loadEmployeeData,
    reloadComponent,
    userRecord?.parentHRMRUserId,
    isParentProfile,
  ]);

  function renderUserCredentialProfileImage() {
    let userName = "";

    if (personalInfoData.firstName && personalInfoData.lastName) {
      userName = `${personalInfoData.firstName.trim()} ${personalInfoData.lastName.trim()}`;
    }

    if (userName === "") {
      return;
    }
    const profilePicUrl = `${process.env.REACT_APP_CUSTOM_IMAGE_PATH}/${personalInfoData.profilePicture}`;

    const profileAvatarAttributes = {
      name: userName,
      isProfileImageExists: personalInfoData.profilePicture ? true : false,
      profileURL: profilePicUrl,
    };

    return <ProfileAvatar {...profileAvatarAttributes} />;
  }

  if (
    checkObjectIsEmpty(personalInfoData) === true ||
    personalInfoState.loading === true
  ) {
    return (
      <BootstrapSpinner animation="border" variant="dark" className="me-2" />
    );
  }

  let userFullName = getUserFullName(personalInfoData);
  const pronoun = renderPrefix(personalInfoData.pronouns);

  return (
    <div className={`${styles.credentialUserProfileContainer} mr-1`}>
      {renderUserCredentialProfileImage()}
      <span className="d-lg-inline text-gray-600 small">
        {userFullName}, <strong>{personalInfoData.credentialsName}</strong>
        <strong className={styles.pronoun}>
          {pronoun ? ` (${pronoun})` : ""}
        </strong>
      </span>
    </div>
  );
};

export default CredUserProfile;
