const pageTitles = {
  default: "Hipaa Compliance",
  dashboard: "Dashboard | Hipaa Compliance",
  thankyou: "Thankyou",
  error: "Error",
  credentialingProfile: "Credentialing Profile",
  npdb: "NPDB",
  psv: "PSV",
  activityChecklist: "Activity Checklist",
  manageDocuments: "Manage Documents",
  authenticate: "Authenticate | Hipaa Compliance",
  departments: "Departments",
  serviceSites: "Organization's Service Site(s)",
  organizationalContacts: "Organizational Contacts",
  trainingLibrary: "Training Library",
  trainingReport: "Training Report",
  trainingDetailsReport: "Training Details Report",
  expirables: "Expirables",
  manageProfile: "Manage Profile",
  employeeCredentialingProfile: "Provider Credentialing Profile",
  employeeFiles: "Provider Files",
  docuSignReport: "DocuSign Report",
  clinicalStaff: "Licensed Providers",
  nonClinicalStaff: "Non Licensed Staff",
  setupIntegrations: "Setup Integrations",
  dashboardInitial: "Dashboard Initial",
  dashboardReCred: "Dashboard Re-Cred",
  reqToCredentialing: "Request Credentialing",
  importExclusionData: "Import Exclusion Data",
  reporting: "Reports",
  facilities: "Facilities",
};

export default pageTitles;
