import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import { IStaffModel } from "@helpers/interfaces/clinicalAndNonClinicalstaff";
import { IDepartmentModel } from "@helpers/interfaces/departments/Department";
import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";
import moment from "moment";

export function parseStaffList(
  employees: Array<IStaffModel>,
  renderEmployeeProfilePicture: (employee: IStaffModel) => void,
  renderAlertControl: (employee: IStaffModel) => void
) {
  const _employees = [];

  for (let employee of employees) {
    const _employee = {
      ...employee,
      renderEmployeeProfilePicture: renderEmployeeProfilePicture(employee),
      activeStatusLabel: employee.isActive ? "Yes" : "No",
      renderAlertsControl: renderAlertControl(employee),
      startDate: employee.employmentStartDate
        ? moment(employee.employmentStartDate).format("MM/DD/YYYY")
        : "",
      hireDate: employee.dateOfHire
        ? moment(employee.dateOfHire).format("MM/DD/YYYY")
        : "",
    };

    _employees.push(_employee);
  }

  return _employees;
}

export function parseStaffListFiltersLocationData(
  locations: Array<IMastersDataModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _locations: Array<ICheckboxSelectDataStructure> = [];

  for (let location of locations) {
    const object: ICheckboxSelectDataStructure = {
      label: location.name,
      value: String(location.id),
      selected: true,
    };

    _locations.push(object);
  }

  return _locations;
}

export function parseStaffListFiltersDepartmentData(
  departments: Array<IDepartmentModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _departments: Array<ICheckboxSelectDataStructure> = [];

  for (let department of departments) {
    const object: ICheckboxSelectDataStructure = {
      label: department.departmentName,
      value: String(department.departmentId),
      selected: true,
    };

    _departments.push(object);
  }

  return _departments;
}

export function createLicensedProvidersDatasetReport(data: Array<any>) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    const appointmentDate = item.originalAppointmentDate
      ? moment(item.originalAppointmentDate).format("MM/DD/YYYY")
      : "";
    const credFromDate = item.startdate
      ? moment(item.startdate).format("MM/DD/YYYY")
      : "";
    const credToDate = item.endDate
      ? moment(item.endDate).format("MM/DD/YYYY")
      : "";
    // const employementStartDate = item.employeeStartDate
    //   ? moment(item.employeeStartDate).format("MM/DD/YYYY")
    //   : "";
    const privStartDate = item.privilegingStartDate
      ? moment(item.privilegingStartDate).format("MM/DD/YYYY")
      : "";
    const privEndDate = item.privilegingExpiryDate
      ? moment(item.privilegingExpiryDate).format("MM/DD/YYYY")
      : "";

    const _item = {
      "Provider Name": `${item.firstName || ""} ${item.middleName || ""} ${
        item.lastName || ""
      }, ${item.credentialsName || ""}`,
      "Organization Name": item.organizationName || "",
      "Credentialing Status": item.jobCredStatus || "",
      "NPI": item.npiNumber,
      "Speciality": item.specialtyName,
      "Category": item.category || "",
      "Instance ID": item.jobNo || "",
      "Cred Type": reqCredTypeOptions.find(
        (data) => data.value === item.requestCredType
      )?.label,
      "Cred from Date": credFromDate,
      "Cred To Date": credToDate,
      "Original Appointment Date": appointmentDate,
      "Privileging Status": item.enrollmentStatus || "",
      "Privileging Start Date": privStartDate,
      "Privileging End Date": privEndDate,

      // Department: item.department || "",
      // "Active Status": item.orgProviderActiveStatus || "",
      // "Legacy Status": item.legacyStatus || "",
      // "Completed Status": item.isCompleted,
      // "Employement Start Date": employementStartDate,
    };

    _data.push(_item);
  });

  return _data;
}

export function createLicensedProvidersTermDatasetReport(data: Array<any>) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    const terminationDate = item.terminationDate
      ? moment(item.terminationDate).format("MM/DD/YYYY")
      : "";

    const updatedDate = item.updatedDate
      ? moment(item.updatedDate).format("MM/DD/YYYY")
      : "";

    const _item = {
      "UID": item.hrmrUserId,
      "Provider Name": `${item.firstName || ""} ${item.middleName || ""} ${
        item.lastName || ""
      }`,
      "Organization Name": item.organizationName || "",
      "Cred Status": item.credStatus || "",
      "Termination Date": terminationDate,
      "Termination Reason": item.reasonForTermination || "",
      "isActive (in the organization)": item.isActive ? "Yes" : "No",
      "Date Updated": updatedDate,
      "Updated By": item.updatedBy || "",
    };

    _data.push(_item);
  });

  return _data;
}
