import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "@hooks/index";
import { setDefaultReportingFilterData } from "@helpers/configMiddlewares/employeeFiles";
import {
  IReportingDataModel,
  IReportingSearchAndFilterParams,
  IEmployeeModel,
} from "@helpers/interfaces/employeeFiles";
import { IReportingPaginatedState } from "@helpers/uiInterfaces/employeeFiles";
import {
  getEmployeeData,
  getEmployeeFilesReport,
  getExportReporting,
  loadDashboardInitialAndReCredAdvancedSearchList,
} from "@services/employeeFiles";
import { gapsInCredentialingUIActions } from "@features/ui/GapsInCredentialingUI";
import ContentHeader from "@common/ContentHeader";
import Spinner from "@common/spinner/Spinner";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import ProfileAvatar from "@common/profile-avatar/ProfileAvatar";
import {
  parseReportingListFiltersCredOrganizationData,
  parseReportingListFiltersProcessingTypeData,
  parseReportingList,
  createReportingDatasetReport,
} from "./utilities";
import styles from "./Reporting.module.scss";
import reportingListColumns from "@constants/json-data/table-columns/dashboard-initial/dashboardInitialAndReCredListColumns";
import { getOrganizationsByNetworkId } from "@services/master/master.services";
import { authenticationActions } from "@features/authentication/Authentication";
import { setApplicationStorage } from "@utilities/storage";
import { useNavigate } from "react-router";
import AddEditEmployeeEditor from "@pages/employeeFiles/AddEditEmployeesEditor/Emp_AddEditEditor";
import NoContentSection from "@common/no-content-section/NoContentSection";
import EmployeesGapsInCredEditor from "@pages/employeeFiles/EmployeesGapsInCredEditor";
import routesNames from "@constants/routes-names";
import rushReqOptions from "@constants/json-data/masters-data/request-type-options";
import ReportingSelectedFilters from "./ReportingSelectedFilters";
import ReportingFilterEditor from "./ReportingFilterEditor";
import SearchField from "@common/search-field/SearchField";
import BootstrapSpinner from "react-bootstrap/Spinner";
import moment from "moment";
import { exportToExcel } from "@utilities/import-export-excel";
import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import EmployeeRequestReportsTab from "@pages/employeeFiles/EmployeeRequestReportsTab";
import {
  getLicensedProviderReport,
  getLicensedProviderTermReport,
} from "@services/clinical-and-non-clinical-staff";
import {
  createLicensedProvidersDatasetReport,
  createLicensedProvidersTermDatasetReport,
} from "@pages/staffComponentRenderer/utilities";
import { createEmployeeFilesDatasetReport } from "@pages/employeeFiles/utilities";

const DEFAULT_PAGE_LIMIT = 10;

const Reporting = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const reportingState = useAppSelector(
    (state) => state.employeeFiles.employee
  );
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const reportingDataList = reportingState.reportingDataList?.page;

  const [rootState, setRootState] = useState<IReportingPaginatedState>({
    page: 1,
    limit: DEFAULT_PAGE_LIMIT,
    filterData: setDefaultReportingFilterData(),
  });
  const [displayAddEditEmployeeEditor, setDisplayAddEditEmployeeEditor] =
    useState(false);
  const [selectedData, setSelectedData] = useState<IEmployeeModel | null>(null);
  const [displayFilterEditor, setDisplayFilterEditor] = useState(false);
  const [displayAlertsEditor, setDisplayAlertsEditor] = useState(false);
  const [reportExportLoading, setReportExportLoading] = useState(false);
  const [activeRosterReportExportLoading, setActiveRosterReportExportLoading] =
    useState(false);
  const [displayRequestReportsTab, setDisplayRequestReportsTab] =
    useState(false);
  const [
    employeeFilesReportExportLoading,
    setEmployeeFilesReportExportLoading,
  ] = useState(false);
  const [termReportExportLoading, setTermReportExportLoading] = useState(false);

  const loadReportingList = useCallback(
    async (
      page: number,
      limit: number,
      filterData: IReportingSearchAndFilterParams
    ) => {
      const _filterData = {
        search: filterData.search,
        employeeType: filterData.employeeType,
        associatedCredentialer: filterData.associatedCredentialer,
        assignedDataAnalyst: filterData.assignedDataAnalyst,
        assignedManager: filterData.assignedManager,
        requestCredType: filterData.requestCredType,
        processingType: filterData.processingType,
        credJobStatus: filterData.credJobStatus,
        credOrganizationId: filterData.credOrganizationId,
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        orgCreatedFromDate: filterData.orgCreatedFromDate,
        orgCreatedToDate: filterData.orgCreatedToDate,
        orgTerminationFromDate: filterData.orgTerminationFromDate,
        orgTerminationToDate: filterData.orgTerminationToDate,
        orgStatus: filterData.orgStatus,
        logCreationFromDate: filterData.logCreationFromDate,
        logCreationToDate: filterData.logCreationToDate,
      };
      await dispatch(
        loadDashboardInitialAndReCredAdvancedSearchList(
          page,
          limit,
          _filterData
        )
      );
      setDisplayFilterEditor(false);

      setRootState({
        ...rootState,
        page,
        limit,
        filterData,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const loadDependencies = useCallback(async () => {
    const filterData = setDefaultReportingFilterData();
    const credOrganizationObject = await dispatch(
      getOrganizationsByNetworkId(40)
    );
    const credOrgIds = credOrganizationObject.data?.value || [];
    filterData.credOrganizationId =
      parseReportingListFiltersCredOrganizationData(
        credOrgIds,
        filterData.credOrganizationId
      );
    filterData.requestCredType = parseReportingListFiltersProcessingTypeData(
      reqCredTypeOptions,
      filterData.requestCredType
    );
    filterData.processingType = parseReportingListFiltersProcessingTypeData(
      rushReqOptions,
      filterData.processingType
    );

    setRootState((_rootState) => {
      return {
        ..._rootState,
        filterData,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadReportingList]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  const closeAlertsEditor = () => {
    setDisplayAlertsEditor(false);
    dispatch(gapsInCredentialingUIActions.resetState());
  };

  const openAlertsEditor = (employee: IReportingDataModel) => {
    const sliceState = {
      enable: true,
      hrmrUserId: employee.hrmrUserId,
      employeeName: `${employee.firstName} ${employee.lastName}`,
    };
    setDisplayAlertsEditor(true);
    dispatch(
      gapsInCredentialingUIActions.updateGapsInCredentialingState(sliceState)
    );
  };

  const renderAlertsEditor = () => {
    if (displayAlertsEditor === false) {
      return;
    }

    const employeeGapsInCredEditorAttributes = {
      open: displayAlertsEditor,
      onClose: closeAlertsEditor,
    };

    return (
      <EmployeesGapsInCredEditor {...employeeGapsInCredEditorAttributes} />
    );
  };

  const handleSearchFieldControl = (searchText: string = "") => {
    setRootState((prevState) => ({
      ...prevState,
      filterData: {
        ...prevState.filterData,
        search: searchText,
      },
    }));
    const filterData = { ...rootState.filterData, search: searchText };
    loadReportingList(rootState.page, rootState.limit, filterData);
  };

  const renderSearchFieldControl = () => {
    const searchFieldControlAttributes = {
      placeholder: "Search by NPI",
      value: rootState.filterData.search,
      onSearch: handleSearchFieldControl,
      onClear: handleSearchFieldControl,
      onChange(searchText: string) {},
    };

    return <SearchField {...searchFieldControlAttributes} />;
  };

  const handleExportControlClick = async () => {
    setReportExportLoading(true);

    const response = await dispatch(getExportReporting(rootState.filterData));
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = "Report" + timestamp;
      const reportDataset = createReportingDatasetReport(
        response.data,
        rushReqOptions,
        rootState.filterData.logCreationFromDate &&
          rootState.filterData.logCreationToDate
          ? true
          : false
      );

      await exportToExcel(reportDataset, fileName, "Report");

      setReportExportLoading(false);
    } else {
      setReportExportLoading(false); // To stop loading indicator when there's any error while getting export data from api
    }
  };

  const handleEmployeeFilesExportControlClick = async () => {
    setEmployeeFilesReportExportLoading(true);

    const response = await dispatch(getEmployeeFilesReport());
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = "EmployeeFilesReport" + timestamp;
      const reportDataset = createEmployeeFilesDatasetReport(response.data);

      await exportToExcel(reportDataset, fileName, "Report");

      setEmployeeFilesReportExportLoading(false);
    } else {
      setEmployeeFilesReportExportLoading(false); // To stop loading indicator when there's any error while getting export data from api
    }
  };

  const handleActiveRosterExportControlClick = async () => {
    setActiveRosterReportExportLoading(true);

    const response = await dispatch(getLicensedProviderReport());
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = "LicensedProvidersReport" + timestamp;
      const reportDataset = createLicensedProvidersDatasetReport(response.data);

      await exportToExcel(reportDataset, fileName, "Report");

      setActiveRosterReportExportLoading(false);
    } else {
      setActiveRosterReportExportLoading(false); // To stop loading indicator when there's any error while getting export data from api
    }
  };

  const handleTermExportControlClick = async () => {
    setTermReportExportLoading(true);

    const response = await dispatch(getLicensedProviderTermReport());
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = "LicensedProvidersTermReport" + timestamp;
      const reportDataset = createLicensedProvidersTermDatasetReport(
        response.data
      );

      await exportToExcel(reportDataset, fileName, "Report");

      setTermReportExportLoading(false);
    } else {
      setTermReportExportLoading(false); // To stop loading indicator when there's any error while getting export data from api
    }
  };

  const renderExportReportControl = () => {
    let contentNode: any = "Export Report";

    if (reportExportLoading === true) {
      contentNode = (
        <BootstrapSpinner
          animation="border"
          variant="dark"
          size="sm"
          className="m-2"
        />
      );
    }

    const exportControlAttributes = {
      title: "Export report",
      disabled: reportExportLoading || !rootState.filterData.credJobStatus,
      className: `btn btnorg float-end`,
      onClick: handleExportControlClick,
    };

    return <button {...exportControlAttributes}>{contentNode}</button>;
  };

  const renderEmployeeFilesExportReportControl = () => {
    let contentNode: any = "Export Provider Report";

    if (employeeFilesReportExportLoading === true) {
      contentNode = (
        <BootstrapSpinner
          animation="border"
          variant="dark"
          size="sm"
          className="m-2"
        />
      );
    }

    const exportControlAttributes = {
      title: "Export provider report",
      disabled: employeeFilesReportExportLoading,
      className: `btn btnorg float-end`,
      onClick: handleEmployeeFilesExportControlClick,
    };

    return <button {...exportControlAttributes}>{contentNode}</button>;
  };

  const renderExportActiveRosterReportControl = () => {
    let contentNode: any = "Export Active Roster";

    if (activeRosterReportExportLoading === true) {
      contentNode = (
        <BootstrapSpinner
          animation="border"
          variant="dark"
          size="sm"
          className="m-2"
        />
      );
    }

    const exportReportControlAttributes = {
      title: "Export Active Roster",
      disabled: activeRosterReportExportLoading,
      className: `btn btnorg float-end`,
      onClick: handleActiveRosterExportControlClick,
    };

    return <button {...exportReportControlAttributes}>{contentNode}</button>;
  };

  const renderTermExportReportControl = () => {
    let contentNode: any = "Term Export";

    if (termReportExportLoading === true) {
      contentNode = (
        <BootstrapSpinner
          animation="border"
          variant="dark"
          size="sm"
          className="m-2"
        />
      );
    }

    const termExportReportControlAttributes = {
      title: "Term Export",
      disabled: termReportExportLoading,
      className: `btn btnorg float-end`,
      onClick: handleTermExportControlClick,
    };

    return (
      <button {...termExportReportControlAttributes}>{contentNode}</button>
    );
  };

  const renderHeader = () => {
    const searchAndFilterControlAttributes = {
      className: `btn btnorg float-end mx-1 my-2`,
      onClick() {
        setDisplayFilterEditor(true);
      },
    };

    const selectedFiltersAttributes = {
      filters: rootState.filterData,
      onFilterChange(filters: IReportingSearchAndFilterParams) {
        loadReportingList(1, rootState.limit, filters);
      },
    };

    const requestReportControlAttributes = {
      className: "btn btnorg float-end",
      onClick() {
        setDisplayRequestReportsTab(true);
      },
    };

    return (
      <div className={`row align-items-center ${styles.rowContanier} mb-3`}>
        <div className="col-md-5 col-xs-12 col-sm-12 d-flex">
          <ReportingSelectedFilters {...selectedFiltersAttributes} />
        </div>
        <div className={`col-md-7 col-xs-12 col-sm-12 ${styles.btnContainer}`}>
          {renderSearchFieldControl()}
          <button {...searchAndFilterControlAttributes}>Search & Filter</button>
          <button {...requestReportControlAttributes}>Request Reports</button>
          {renderEmployeeFilesExportReportControl()}
          {renderExportActiveRosterReportControl()}
          {renderTermExportReportControl()}
          {renderExportReportControl()}
        </div>
      </div>
    );
  };

  const handleOpenEmployeeFilesEditor = async (hrmrUserId: number) => {
    const response = await dispatch(getEmployeeData(hrmrUserId));
    if (response.status === 200) {
      setDisplayAddEditEmployeeEditor(true);
      setSelectedData(response.data);
    }
  };

  const renderTableActionElements = (data: IReportingDataModel) => {
    const _userRecord = { ...userRecord, hrmrUserId: data.hrmrUserId };

    const employeeFilesControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        handleOpenEmployeeFilesEditor(data.hrmrUserId);
      },
    };

    const credProfileControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(routesNames.credentialingProfileRoute);
      },
    };

    const npdbControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(routesNames.npdbRoute);
      },
    };

    const psvControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(routesNames.psvRoute);
      },
    };

    const ordersControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(`/orderSummary/${data.hrmrUserId}`);
      },
    };

    return (
      <div className={styles.actionElements}>
        <button {...employeeFilesControlAttributes}>Provider File</button> |{" "}
        <button {...credProfileControlAttributes}>Cred Profile</button> |{" "}
        <button {...npdbControlAttributes}>NPDB</button> |{" "}
        <button {...psvControlAttributes}>PSV</button> |{" "}
        <button {...ordersControlAttributes}>Orders</button>
      </div>
    );
  };

  const renderEmployeeProfilePicture = (employee: IReportingDataModel) => {
    const employeeFullName = `${employee.firstName?.trim()} ${employee.lastName?.trim()}`;
    const profilePicUrl = `${process.env.REACT_APP_CUSTOM_IMAGE_PATH}/${employee.profilePicture}`;

    const profileAvatarAttributes = {
      name: employeeFullName,
      profileURL: profilePicUrl,
      isProfileImageExists: employee.profilePicture ? true : false,
      dynamicColor: true,
      className: styles.profileAvatar,
    };

    return <ProfileAvatar {...profileAvatarAttributes} />;
  };

  const renderAlertControl = (employee: IReportingDataModel) => {
    let contentNode = (
      <label className={styles.alertLabelNode}>No alerts</label>
    );

    if (employee.totalExpDocsCount !== 0) {
      const alertIconAttributes = {
        className: styles.alertIcon,
        icon: faCircleExclamation,
      };

      contentNode = (
        <label className={styles.alertLabelNode}>
          {employee.totalExpDocsCount} alerts{" "}
          <FontAwesomeIcon {...alertIconAttributes} />
        </label>
      );
    }

    const alertControlAttributes = {
      className: "table-link-button",
      onClick() {
        openAlertsEditor(employee);
      },
    };

    return <button {...alertControlAttributes}>{contentNode}</button>;
  };

  const renderSendReminderControl = (employee: IReportingDataModel) => {
    const sendReminderControlAttributes = {
      title: "Send Reminder",
      className: styles.sendReminderControl,
      onClick() {},
    };

    return (
      <button {...sendReminderControlAttributes}>
        <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
      </button>
    );
  };

  const renderAddEditEmployeeEditor = () => {
    if (displayAddEditEmployeeEditor === false) {
      return;
    }

    const employeeEditorAttributes = {
      data: selectedData,
      open: displayAddEditEmployeeEditor,
      isTraining: false,
      onClose() {
        setDisplayAddEditEmployeeEditor(false);
        setSelectedData(null);
      },
      updateSelectedData() {},
    };

    return <AddEditEmployeeEditor {...employeeEditorAttributes} />;
  };

  const renderContent = () => {
    if (reportingState.loading) {
      return <Spinner />;
    }

    if (!reportingDataList || reportingDataList.length === 0) {
      return <NoContentSection />;
    }

    const parsedEmployees = parseReportingList(
      reportingDataList,
      renderEmployeeProfilePicture,
      renderAlertControl,
      renderSendReminderControl
    );

    const paginatedTableAttributes = {
      columns: reportingListColumns,
      rows: parsedEmployees,
      actionElement: renderTableActionElements,
      currentPage: rootState.page,
      totalRecord: reportingState.reportingDataList?.paging?.totalItems,
      rowsPerPage: rootState.limit,
      onPageLimitChange(limit: number) {
        loadReportingList(1, limit, rootState.filterData);
      },
      onPageChange(page: number) {
        loadReportingList(page, rootState.limit, rootState.filterData);
      },
    };

    return <PaginatedTable {...paginatedTableAttributes} />;
  };

  const renderRequestReportsTab = () => {
    if (displayRequestReportsTab === false) {
      return;
    }

    const requestReportsAttributes = {
      open: displayRequestReportsTab,
      onClose() {
        setDisplayRequestReportsTab(false);
      },
    };

    return <EmployeeRequestReportsTab {...requestReportsAttributes} />;
  };

  const renderFiltersEditor = () => {
    if (displayFilterEditor === false) {
      return;
    }

    const reportingFiltersEditorAttributes = {
      open: displayFilterEditor,
      filterData: rootState.filterData,
      onSearch(data: IReportingSearchAndFilterParams) {
        loadReportingList(rootState.page, rootState.limit, data);
      },
      onClose() {
        setDisplayFilterEditor(false);
      },
    };

    return <ReportingFilterEditor {...reportingFiltersEditorAttributes} />;
  };

  const contentHeaderAttributes = {
    title: "Reporting",
    instructions: [],
  };

  return (
    <div id={styles.reportingMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className={`container-fluid ${styles.reportingContainer}`}>
        {renderHeader()}
        {renderContent()}
        {renderFiltersEditor()}
        {renderAddEditEmployeeEditor()}
        {renderAlertsEditor()}
        {renderRequestReportsTab()}
      </div>
    </div>
  );
};

export default Reporting;
