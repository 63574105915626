import React, { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/index";
import statusTypes from "@constants/json-data/status-types";
import { setDefaultDashboardInitialAndReCredFilterData } from "@helpers/configMiddlewares/employeeFiles";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";
import { IDashboardInitialAndReCredFilterEditorProps } from "@helpers/uiInterfaces/employeeFiles";
import CheckboxSelect from "@common/checkbox-select/CheckboxSelect";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import SearchField from "@common/search-field/SearchField";
import {
  parseCredJobSelectControlsData,
  parseSelectContactsControlsData,
  parseSelectControlsData,
} from "@utilities/index";
import styles from "./DashboardInitialAndReCred.module.scss";
import { getAllEmployeeStatus } from "@services/master/master.services";
import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import { getOrganizationContactsByType } from "@services/organizations/contact";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import { getAllJobCredStatus } from "@services/tracking-report";

const DashboardInitialAndReCredFilterEditor: React.FC<
  IDashboardInitialAndReCredFilterEditorProps
> = (props) => {
  const { open, filterData, isAdvancedSearch, onSearch, onClose } = props;

  const dispatch = useDispatch<any>();

  const employeeStatus = useAppSelector(
    (state) => state.masters.allEmployeeStatus
  );
  const jobCredStatus = useAppSelector(
    (state) => state.trackingReport.jobCredStatus
  );

  const [_filterData, _setFilterData] = useState(filterData);
  const [credentialerOptions, setCredentialerOptions] = useState([]);
  const [analystOptions, setAnalystOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [errors, setErrors] = useState({
    credJobStatusError: "",
    credOrgError: "",
    processingTypeError: "",
  });

  const loadDependencies = useCallback(async () => {
    await dispatch(getAllEmployeeStatus());
    await dispatch(getAllJobCredStatus());
    const credentialerRes = await dispatch(getOrganizationContactsByType(9));
    if (credentialerRes.status === 200)
      setCredentialerOptions(credentialerRes.data);

    const analystRes = await dispatch(getOrganizationContactsByType(10));
    if (analystRes.status === 200) setAnalystOptions(analystRes.data);

    const managerRes = await dispatch(getOrganizationContactsByType(11));
    if (managerRes.status === 200) setManagerOptions(managerRes.data);
  }, [dispatch]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  useEffect(() => {
    _setFilterData(filterData);
  }, [filterData]);

  function handleSearchFieldControl(searchText: string = "") {
    _setFilterData((latestFilterData) => {
      let data = {
        ...latestFilterData,
        search: searchText,
      };
      onSearch(data, isAdvancedSearch);

      return data;
    });
  }

  function handleSelectControlChange(name: string, value: string) {
    _setFilterData((latestFilterData) => {
      return {
        ...latestFilterData,
        [name]: value,
      };
    });
  }

  function renderSearchFieldControl() {
    const searchFieldControlAttributes = {
      placeholder: "Search by First or Last Name",
      value: _filterData.search,
      onSearch: handleSearchFieldControl,
      onClear: handleSearchFieldControl,
      onChange(searchText: string) {
        _setFilterData({
          ..._filterData,
          search: searchText,
        });
      },
    };

    return (
      <div className="form-group">
        <div className="lbl">
          <label className="d-flex align-center">
            Search By First or Last Name
          </label>
        </div>
        <SearchField {...searchFieldControlAttributes} />
      </div>
    );
  }

  const validateRequiredFields = () => {
    let isValid = true;
    let updatedErrors = { ...errors }; // Clone the current errors to prevent mutation

    // Check for missing "credJobStatus"
    if (!_filterData.credJobStatus) {
      updatedErrors.credJobStatusError = "Required";
      isValid = false;
    } else {
      updatedErrors.credJobStatusError = "";
    }

    // Check for missing "credOrganizationId"
    if (
      _filterData.credOrganizationId.filter((data) => data.selected).length ===
      0
    ) {
      updatedErrors.credOrgError = "Required";
      isValid = false;
    } else {
      updatedErrors.credOrgError = "";
    }

    // Check for missing "processingType"
    if (
      _filterData.processingType.filter((data) => data.selected).length === 0
    ) {
      updatedErrors.processingTypeError = "Required";
      isValid = false;
    } else {
      updatedErrors.processingTypeError = "";
    }

    setErrors(updatedErrors);
    return isValid;
  };

  function renderControlsSection() {
    const cancelControlAttributes = {
      className: "btn btnorg w100 border-button",
      onClick: onClose,
    };

    const searchControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        if (validateRequiredFields()) onSearch(_filterData, isAdvancedSearch);
      },
    };

    const resetControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        const defaultValues = setDefaultDashboardInitialAndReCredFilterData(
          window.location.pathname === "/dashboard-initial"
            ? "initial"
            : "recred"
        );
        _setFilterData(defaultValues);
        onSearch(defaultValues, isAdvancedSearch);
      },
    };

    return (
      <div className="mdl-footer">
        <button {...cancelControlAttributes}>Cancel</button>
        <button {...resetControlAttributes}>Reset</button>
        <button {...searchControlAttributes}>Search</button>
      </div>
    );
  }

  const renderBasicSearchFields = () => {
    const employeeTypeControlAttributes = {
      label: "Provider Type",
      name: "employeeType",
      disableDefaultSelectOption: true,
      data: statusTypes,
      value: _filterData.employeeType,
      onChange: handleSelectControlChange,
    };

    const locationsControlAttributes = {
      label: "Locations",
      data: _filterData.locations,
      onSelect(data: Array<ICheckboxSelectDataStructure>) {
        _setFilterData({
          ..._filterData,
          locations: data,
        });
      },
    };

    const departmentsControlAttributes = {
      label: "Departments",
      data: _filterData.department,
      onSelect(data: Array<ICheckboxSelectDataStructure>) {
        _setFilterData({
          ..._filterData,
          department: data,
        });
      },
    };

    const employeeStatusControlAttributes = {
      label: "Provider Status",
      name: "employeeStatus",
      disableDefaultSelectOption: true,
      data: parseSelectControlsData(employeeStatus),
      value: _filterData.employeeStatus,
      onChange: handleSelectControlChange,
    };

    return (
      <>
        {renderSearchFieldControl()}
        <FormSelectInputControl {...employeeTypeControlAttributes} />
        <CheckboxSelect {...locationsControlAttributes} />
        <CheckboxSelect {...departmentsControlAttributes} />
        <FormSelectInputControl {...employeeStatusControlAttributes} />
      </>
    );
  };

  const renderAdvancedSearchFields = () => {
    const associatedCredentialerControlAttributes = {
      label: "Associated Credentialer",
      name: "associatedCredentialer",
      value: _filterData.associatedCredentialer,
      data: parseSelectContactsControlsData(credentialerOptions),
      onChange: handleSelectControlChange,
    };

    const assignedDataAnalystControlAttributes = {
      label: "Assigned Data Analyst",
      name: "assignedDataAnalyst",
      value: _filterData.assignedDataAnalyst,
      data: parseSelectContactsControlsData(analystOptions),
      onChange: handleSelectControlChange,
    };

    const assignedManagerControlAttributes = {
      label: "Assigned Manager",
      name: "assignedManager",
      value: _filterData.assignedManager,
      data: parseSelectContactsControlsData(managerOptions),
      onChange: handleSelectControlChange,
    };

    const requestCredTypeControlAttributes = {
      label: "Request Cred Type",
      name: "requestCredType",
      value: _filterData.requestCredType,
      disabled: true,
      data: reqCredTypeOptions,
      onChange: handleSelectControlChange,
    };

    const processingTypeControlAttributes = {
      label: "Processing Type",
      data: _filterData.processingType,
      required: true,
      error: errors.processingTypeError,
      onSelect(data: Array<ICheckboxSelectDataStructure>) {
        _setFilterData({
          ..._filterData,
          processingType: data,
        });
      },
      onBlur: validateRequiredFields,
    };

    const credJobStatusControlAttributes = {
      label: "Cred/Instance Status",
      name: "credJobStatus",
      required: true,
      error: errors.credJobStatusError,
      value: _filterData.credJobStatus,
      data: parseCredJobSelectControlsData(jobCredStatus),
      onChange: handleSelectControlChange,
      onBlur: validateRequiredFields,
    };

    const credOrganizationIdControlAttributes = {
      label: "Cred Organizations",
      data: _filterData.credOrganizationId.filter(
        (data) => data.value !== "243e19fa-4613-4ae3-9b70-1711e12bcab3"
      ),
      required: true,
      error: errors.credOrgError,
      onSelect(data: Array<ICheckboxSelectDataStructure>) {
        _setFilterData({
          ..._filterData,
          credOrganizationId: data,
        });
      },
      onBlur: validateRequiredFields,
    };

    const startDateControlChange = {
      label: "Start Date",
      name: "dob",
      value: _filterData.startDate,
      onChange(value: string) {
        _setFilterData({
          ..._filterData,
          startDate: value,
        });
      },
    };

    const endDateControlChange = {
      label: "End Date",
      name: "dob",
      value: _filterData.endDate,
      onChange(value: string) {
        _setFilterData({
          ..._filterData,
          endDate: value,
        });
      },
    };

    return (
      <>
        <FormSelectInputControl {...associatedCredentialerControlAttributes} />
        <FormSelectInputControl {...assignedDataAnalystControlAttributes} />
        <FormSelectInputControl {...assignedManagerControlAttributes} />
        <FormSelectInputControl {...requestCredTypeControlAttributes} />
        <CheckboxSelect {...processingTypeControlAttributes} />
        <FormSelectInputControl {...credJobStatusControlAttributes} />
        <CheckboxSelect {...credOrganizationIdControlAttributes} />
        <FormDateInputControl {...startDateControlChange} />
        <FormDateInputControl {...endDateControlChange} />
      </>
    );
  };

  function renderContent() {
    return (
      <Offcanvas.Body
        className={styles.dashboardInitialAndReCredFilterEditorMain}
      >
        <div>
          {isAdvancedSearch
            ? renderAdvancedSearchFields()
            : renderBasicSearchFields()}
        </div>

        {renderControlsSection()}
      </Offcanvas.Body>
    );
  }

  return (
    <Offcanvas show={open} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Search & Filter List</Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default DashboardInitialAndReCredFilterEditor;
