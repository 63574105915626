import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICredentialProfileTab } from "@helpers/interfaces/credentialProfile";

const initialState = {
  credentialProfileTabs: [] as Array<ICredentialProfileTab>,
  showTabChangePopUp: false,
  isParentProfile: false,
  loading: false,
};

const credentialProfile = createSlice({
  name: "credentialProfile",
  initialState,
  reducers: {
    updateCredentialProfileTabs: (
      state,
      action: PayloadAction<Array<ICredentialProfileTab>>
    ) => {
      state.credentialProfileTabs = action.payload;
    },

    updateTabChangePopUp: (state, action: PayloadAction<boolean>) => {
      state.showTabChangePopUp = action.payload;
    },

    updateIsParentProfile: (state, action: PayloadAction<boolean>) => {
      state.isParentProfile = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetCredentialProfileTabs: (state) => {
      state.credentialProfileTabs = [] as Array<ICredentialProfileTab>;
    },
  },
});

export const credentialProfileActions = credentialProfile.actions;
export default credentialProfile.reducer;
