import { IDepartmentModel } from "@helpers/interfaces/departments/Department";
import { IEmployeeModel } from "@helpers/interfaces/employeeFiles";
import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";
import moment from "moment";

export function parseEmployeeList(
  employees: Array<IEmployeeModel>,
  renderEmployeeProfilePicture: (employee: IEmployeeModel) => void,
  renderAlertControl: (employee: IEmployeeModel) => void
) {
  const _employees = [];

  for (let employee of employees) {
    const _employee = {
      ...employee,
      employeeName: `${employee.firstName} ${employee.lastName}${
        employee?.credentialsName ? "," : ""
      } ${employee?.credentialsName ? employee.credentialsName : ""}`,
      startDate: employee.employmentStartDate
        ? moment(employee.employmentStartDate).format("MM/DD/YYYY")
        : "",
      hireDate: employee.dateOfHire
        ? moment(employee.dateOfHire).format("MM/DD/YYYY")
        : "",
      renderEmployeeProfilePicture: renderEmployeeProfilePicture(employee),
      activeStatusLabel: employee.isActive ? "Yes" : "No",
      renderAlertsControl: renderAlertControl(employee),
    };

    _employees.push(_employee);
  }

  return _employees;
}

export function parseEmployeeListFiltersLocationData(
  locations: Array<IMastersDataModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _locations: Array<ICheckboxSelectDataStructure> = [];

  for (let location of locations) {
    const object: ICheckboxSelectDataStructure = {
      label: location.name,
      value: String(location.id),
      selected: true,
    };

    _locations.push(object);
  }

  return _locations;
}

export function parseEmployeeListFiltersDepartmentData(
  departments: Array<IDepartmentModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _departments: Array<ICheckboxSelectDataStructure> = [];

  for (let department of departments) {
    const object: ICheckboxSelectDataStructure = {
      label: department.departmentName,
      value: String(department.departmentId),
      selected: true,
    };

    _departments.push(object);
  }

  return _departments;
}

export function createEmployeeFilesDatasetReport(data: Array<any>) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    const dob = item.dob ? moment(item.dob).format("MM/DD/YYYY") : "";
    const dateOfHire = item.dateOfHire
      ? moment(item.dateOfHire).format("MM/DD/YYYY")
      : "";
    const terminationDate = item.terminationDate
      ? moment(item.terminationDate).format("MM/DD/YYYY")
      : "";
    const startDate = item.employmentStartDate
      ? moment(item.employmentStartDate).format("MM/DD/YYYY")
      : "";
    const dateAddedToWatchdog = item.dateAddedToWatchdog
      ? moment(item.dateAddedToWatchdog).format("MM/DD/YYYY")
      : "";

    const _item = {
      "Last Name": item.lastName,
      "First Name": item.firstName,
      "Credentials": item.credentialsName,
      "Job Title": item.title,
      "Department": item.department,
      "Date of Hire": dateOfHire,
      "Date Added to Watchdog": dateAddedToWatchdog,
      "Start Date": startDate,
      "Supervisor": item.supervisorName,
      "Billing Supervisor": item.billingSupervisorName,
      "Email": item.email,
      "Status": item.employeeStatus,
      "Is Active": item.isActive,
      "Date Of Birth": dob,
      "Alternate Email": item.alternateEmail,
      "Termination Date": terminationDate,
      "Emergency Contact Name": item.emergencyContactName,
      "Mobile No": item.mobileNo,
      "Emergency Contact": item.emergencyContact,
      "Comment": item.comment,
      "Locations": item.locations,
      "Cred Contact Name": item.credContactName,
      "Cred Primary Email": item.credPrimaryEmail,
      "Cred Secondary Email": item.credSecondaryEmail,
      "Cred Phone": item.credPhone,
      "Cred Fax": item.credFax,
      "Cred Address": item.credAddress,
      "Cred Zip": item.credZip,
      "Cred Phone Extension": item.credPhoneExt,
      "Cred City Name": item.credCityName,
      "Cred State Name": item.credStateName,
    };

    _data.push(_item);
  });

  return _data;
}

export function createProviderCountDatasetReport(data: Array<any>) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    const _item = {
      "Credentials Name": item.credentialsName,
      "No. of Providers": item.countByCredentials,
    };

    _data.push(_item);
  });

  return _data;
}
