import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "@hooks/index";
import commonConstants from "@constants/common-constants";
import sidebarItems from "@constants/json-data/sidebar-items";
import {
  ISidebarItem,
  ISidebarItemChildren,
} from "@helpers/uiInterfaces/sidebar";
import companyLogo from "@images/companyLogo.png";
import { createCustomDomainUrl } from "@utilities/index";
import { useDispatch } from "react-redux";
import { authenticationActions } from "@features/authentication/Authentication";

const WATCHDOG_APP_URL = process.env.REACT_APP_WATCHDOG_APP_URL;
const CUSTOM_LOGO_URL = process.env.REACT_APP_CUSTOM_LOGO_PATH;

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const DOMAIN_CUSTOM_PATH = createCustomDomainUrl(userRecord.customDomain);

  const [navigationLinksExpanded, setNavigationLinksExpanded] = useState<
    null | number
  >(null);
  const [userId, setUserId] = useState<string | null>();

  useEffect(() => {
    setUserId(userRecord.id);
  }, [userRecord]);

  const handleLinkClick = (e: any) => {
    dispatch(authenticationActions.resetUserRecord());
  };

  function checkParentOptionIsActive(option: ISidebarItem) {
    if (location.pathname === option.path) {
      return true;
    }

    // checking current parent contains current route as children
    if (option.children.find((item) => item.path === location.pathname)) {
      return true;
    }

    return false;
  }

  function handleMenuVisibleInputClick(option: ISidebarItem) {
    // if user click on the same already expanded navigation control, just collapse that control
    if (navigationLinksExpanded === option.id) {
      return setNavigationLinksExpanded(null);
    }

    setNavigationLinksExpanded(option.id);
  }

  function renderChevronIcon(option: ISidebarItem) {
    if (option.children.length === 0) {
      return;
    }

    return <i className="right fas fa-chevron-right" />;
  }

  function renderSidebarOptionChildrenItem(
    option: ISidebarItemChildren,
    index: number
  ) {
    let sidebarNavLinkClassName = "nav-link";

    if (location.pathname === option.path) {
      sidebarNavLinkClassName += " menuactive";
    }

    if (option.externalLink === true) {
      let externalLink =
        userRecord.useCustomDomain &&
        userRecord.customDomain &&
        userRecord.logoPath
          ? `${DOMAIN_CUSTOM_PATH}${option.path}/${userId}`
          : `${WATCHDOG_APP_URL}${option.path}/${userId}`;

      if (option.externalLinkSuffixes) {
        externalLink += option.externalLinkSuffixes;
      }

      return (
        <li className="nav-item" key={index}>
          <a
            href={externalLink}
            className={sidebarNavLinkClassName}
            onClick={handleLinkClick}
          >
            <i className="fas fa-arrow-circle-right" />
            <p>{option.label}</p>
          </a>
        </li>
      );
    }

    return (
      <li className="nav-item" key={index}>
        <Link to={option.path} className={sidebarNavLinkClassName}>
          <i className="fas fa-arrow-circle-right" />
          <p>{option.label}</p>
        </Link>
      </li>
    );
  }

  function renderSidebarOptionChildren(children: Array<ISidebarItemChildren>) {
    if (children.length === 0) {
      return;
    }

    if (navigationLinksExpanded === null) {
      return;
    }

    return (
      <ul className="nav nav-treeview">
        {children.map((item, index) =>
          renderSidebarOptionChildrenItem(item, index)
        )}
      </ul>
    );
  }

  function renderMenuVisibleInput(option: ISidebarItem) {
    if (option.children.length === 0) {
      return;
    }

    const menuVisibleInputControlAttributes = {
      type: "radio",
      name: "menu",
      className: "menuVisible",
      onClick() {
        handleMenuVisibleInputClick(option);
      },
    };

    return <input {...menuVisibleInputControlAttributes} />;
  }

  function renderSidebarParentOptionLink(option: ISidebarItem) {
    if (option.externalLink === true) {
      const externalLink =
        userRecord.useCustomDomain &&
        userRecord.customDomain &&
        userRecord.logoPath
          ? `${DOMAIN_CUSTOM_PATH}${option.path}/${userId}`
          : `${WATCHDOG_APP_URL}${option.path}/${userId}`;
      return (
        <a href={externalLink} className="nav-link" onClick={handleLinkClick}>
          <span className={option.iconClassName} />
          <p>
            {option.label} {renderChevronIcon(option)}
          </p>
        </a>
      );
    }

    return (
      <Link to={option.path} className="nav-link">
        <span className={option.iconClassName} />
        <p>
          {option.label} {renderChevronIcon(option)}
        </p>
      </Link>
    );
  }

  function renderSidebarNavigationLink(option: ISidebarItem, index: number) {
    // if sidebar option is 'Super Admin' and user is not 'Administrator' -> then don't show that option
    if (
      option.label === "Super Admin" &&
      userRecord.userRole !== commonConstants.ADMINISTRATOR_USER_ROLE
    ) {
      return;
    }

    let sidebarItemClassName = "nav-item";

    if (checkParentOptionIsActive(option) === true) {
      sidebarItemClassName += " parentActive";
    }

    return (
      <li className={sidebarItemClassName} key={index}>
        {renderMenuVisibleInput(option)}

        <label className="title" htmlFor="menu">
          {renderSidebarParentOptionLink(option)}
        </label>

        {renderSidebarOptionChildren(option.children)}
      </li>
    );
  }

  const companyLogoAttributes = {
    src:
      userRecord.useCustomDomain &&
      userRecord.logoPath &&
      userRecord.customDomain
        ? `${CUSTOM_LOGO_URL}/${userRecord.logoPath}`
        : companyLogo,
    className: "brand-image img-circle elevation-3",
  };

  const applicationDashboardLink =
    process.env.REACT_APP_WATCHDOG_APP_URL + "/dashboard";

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a
        href={applicationDashboardLink}
        className="brand-link"
        onClick={handleLinkClick}
      >
        <img alt="companyLogo" {...companyLogoAttributes} />
      </a>

      <div className="sidebar">
        <nav className="mt-0">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {sidebarItems.map((option, index) =>
              renderSidebarNavigationLink(option, index)
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
