import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const dashboardInitialAndReCredListColumns: Array<IApplicationTableColumn> = [
  {
    id: "renderEmployeeProfilePicture",
    label: "Picture",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "lastName",
    label: "Last Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "firstName",
    label: "First Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "credentialsName",
    label: "Credentials",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "npiNumber",
    label: "NPI",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "statusOfApplication",
    label: "Current Cred Status",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "workflowStartDate",
    label: "Workflow Start Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "workflowEndDate",
    label: "Workflow End Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "renderAlertsControl",
    label: "Alerts",
    type: applicationTableColumnsTypes.CUSTOM,
  },
];

export default dashboardInitialAndReCredListColumns;
