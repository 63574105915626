import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IDashboardInitialAndReCredModel,
  IEmployeeModel,
  IEmployeePaginationModel,
  IReportingModel,
} from "@helpers/interfaces/employeeFiles";
import { setDefaultEmployeeData } from "@helpers/configMiddlewares/employeeFiles";

const initialState = {
  employeeData: {} as IEmployeeModel,
  employeesList: {} as IEmployeePaginationModel,
  dashboardInitialDataList: {} as IDashboardInitialAndReCredModel,
  dashboardReCredDataList: {} as IDashboardInitialAndReCredModel,
  reportingDataList: {} as IReportingModel,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    updateEmployeeData: (state, action: PayloadAction<IEmployeeModel>) => {
      state.employeeData = action.payload;
    },

    updateEmployeeList: (
      state,
      action: PayloadAction<IEmployeePaginationModel>
    ) => {
      state.employeesList = action.payload;
    },

    updateDashboardInitialDataList: (
      state,
      action: PayloadAction<IDashboardInitialAndReCredModel>
    ) => {
      state.dashboardInitialDataList = action.payload;
    },

    updateDashboardReCredDataList: (
      state,
      action: PayloadAction<IDashboardInitialAndReCredModel>
    ) => {
      state.dashboardReCredDataList = action.payload;
    },

    updateReportingDataList: (
      state,
      action: PayloadAction<IReportingModel>
    ) => {
      state.reportingDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.employeeData = setDefaultEmployeeData();
    },
  },
});

export const employeeActions = employeeSlice.actions;
export default employeeSlice.reducer;
