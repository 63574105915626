import routesNames from "@constants/routes-names";
import { ISidebarItem } from "@helpers/uiInterfaces/sidebar";

const sidebarItems: Array<ISidebarItem> = [
  {
    id: 1,
    iconClassName: "icon-dasboard",
    label: "Dashboard",
    path: "/Dashboard/Index",
    externalLink: true,
    children: [],
  },
  {
    id: 2,
    iconClassName: "icon-Organizational",
    label: "Organizational Profile",
    path: "",
    children: [
      {
        label: "Service Site(s)",
        path: routesNames.serviceSitesRoute,
      },
      {
        label: "Contacts",
        path: routesNames.organizationalContactsRoute,
      },
      {
        label: "Activity Checklist",
        path: routesNames.activityChecklistRoute,
      },
      {
        label: "Departments",
        path: routesNames.departmentsRoute,
      },
      {
        label: "Professional Liability",
        path: "/MalPractice/Index",
        externalLink: true,
      },
      {
        label: "Setup Alerts",
        path: "/Credentialing/ManageAlert",
        externalLink: true,
      },
      {
        label: "Setup Integrations",
        path: routesNames.setupIntegrationsRoute,
      },
      // {
      //   label: "Import/Export Data",
      //   path: "/Home/UploadExcel",
      //   externalLink: true,
      // }, // removed as told by sunny
    ],
  },
  {
    id: 3,
    iconClassName: "icon-hr",
    label: "HR / Training",
    path: "",
    children: [
      // {
      //   label: "Applicant Tracking",
      //   path: "/EMRUser/ApplicantTracking",
      //   externalLink: true,
      // }, // removed as told by sunny
      {
        label: "Employee Files",
        path: routesNames.employeeFiles,
      },
      {
        label: "Training Library",
        path: routesNames.trainingLibraryRoute,
      },
      {
        label: "Training Report",
        path: routesNames.trainingReportRoute,
      },
    ],
  },
  {
    id: 4,
    iconClassName: "icon-cred",
    label: "Credentialing",
    path: "",
    children: [
      {
        label: "Request Credentialing",
        path: routesNames.reqToCredentialing,
      },
      {
        label: "Licensed Providers",
        path: routesNames.clinicalStaff,
      },
      {
        label: "Non Licensed Staff",
        path: routesNames.nonClinicalStaff,
      },
      {
        label: "Facilities",
        path: routesNames.facilities,
      },
      {
        label: "Manage Documents",
        path: routesNames.manageDocumentsRoute,
      },
      {
        label: "Expirables",
        path: routesNames.expirablesRoute,
      },
      // {
      //   label: "Missing Documents",
      //   path: "/EMRUser/MissingDocuments",
      //   externalLink: true,
      // },
      {
        label: "Order Summary",
        path: `/orderSummary/0`,
      },
      {
        label: "Payer Profiles",
        path: "/Payer/Payers",
        externalLink: true,
      },
      {
        label: "Payer Enrollment",
        path: "/PayerEnrollment/Index",
        externalLink: true,
      },
      {
        label: "Privileging",
        path: "/PayerEnrollment/Privileging",
        externalLink: true,
      },
      {
        label: "Dashboard Initial",
        path: routesNames.dashboardInitialRoute,
      },
      {
        label: "Dashboard Re-Cred",
        path: routesNames.dashboardReCredRoute,
      },
      {
        label: "Reports",
        path: routesNames.reportingRoute,
      },
      // {
      //   label: "Licensing",
      //   path: "/PayerEnrollment/Licensing",
      //   externalLink: true,
      // },
      // {
      //   label: "Service Site Alerts/Reports",
      //   path: "/Location/PayerServiceSiteReport",
      //   externalLink: true,
      // },
      {
        label: "Login Info",
        path: "/Credentialing/LoginInfo",
        externalLink: true,
      },
      // {
      //   label: "Enrollment Report",
      //   path: "/UsageReport/EnrollClientReport",
      //   externalLink: true,
      // },
    ],
  },
  {
    id: 5,
    iconClassName: "icon-vendors",
    label: "Vendors",
    path: "",
    children: [
      {
        label: "Vendor Matrix",
        path: "/Vendor/Index",
        externalLink: true,
      },
    ],
  },
  {
    id: 6,
    iconClassName: "icon-cyber",
    label: "HIPAA / Cyber Security",
    path: "",
    children: [
      {
        label: "Vulnerability Assessment",
        path: "/Vulnerability/Index",
        externalLink: true,
      },
      {
        label: "External Penetration Test",
        path: "/Vulnerability/PenetrationUser",
        externalLink: true,
      },
      {
        label: "PHI Matrix",
        path: "/PHI/Index",
        externalLink: true,
      },
      {
        label: "Device Matrix",
        path: "/HWSW/Index",
        externalLink: true,
      },
      {
        label: "SRA Reports",
        path: "/Session/MyReport",
        externalLink: true,
      },
    ],
  },
  {
    id: 7,
    iconClassName: "icon-risk",
    label: "Risk Management",
    path: "",
    children: [
      {
        label: "HIPAA",
        path: "/Surveys/Index",
        externalLink: true,
        externalLinkSuffixes: "?CId=7",
      },
      {
        label: "HRSA",
        path: "/Surveys/Index",
        externalLink: true,
        externalLinkSuffixes: "?CId=8",
      },
      {
        label: "HIPAA-ONC",
        path: "/Surveys/Index",
        externalLink: true,
        externalLinkSuffixes: "?CId=11",
      },
      {
        label: "OSV - Mock Audit",
        path: "/Surveys/Index",
        externalLink: true,
        externalLinkSuffixes: "?CId=13",
      },
      {
        label: "Peer Review",
        path: "/Surveys/Index",
        externalLink: true,
        externalLinkSuffixes: "?CId=16",
      },
      {
        label: "Liability Loss Prevention",
        path: "/Surveys/Index",
        externalLink: true,
        externalLinkSuffixes: "?CId=26",
      },
    ],
  },
  {
    id: 8,
    iconClassName: "icon-policy",
    label: "Policies and Procedures",
    path: "",
    children: [
      {
        label: "Policy Builder",
        path: "/PPRepository/PolicyProcedures",
        externalLink: true,
      },
      {
        label: "Document Library",
        path: "/PPRepository/Index",
        externalLink: true,
      },
    ],
  },
  {
    id: 9,
    iconClassName: "icon-lib",
    label: "Resource Library",
    path: "/Home/Download",
    externalLink: true,
    children: [],
  },
  {
    id: 10,
    iconClassName: "icon-users",
    label: "Users",
    path: "/UserList/Index",
    externalLink: true,
    children: [],
  },
  {
    id: 11,
    iconClassName: "icon-admin",
    label: "Admin",
    path: "",
    children: [
      {
        label: "Penetration Request",
        path: "/Vulnerability/PenetrationAdmin",
        externalLink: true,
      },
      {
        label: "Role Management",
        path: "/RoleRights/Index",
        externalLink: true,
      },
      {
        label: "Payment Method",
        path: "/Payment/Index",
        externalLink: true,
      },
      {
        label: "Quote / Subscriptions",
        path: "/Payment/UserQuotation",
        externalLink: true,
      },
      {
        label: "Manage Alerts",
        path: "/RoleRights/ManageAlert",
        externalLink: true,
      },
      {
        label: "Custom Training",
        path: "/Assessment/OnlineAssessment",
        externalLink: true,
      },
      {
        label: "Task Tracking",
        path: "/TaskTracking/Index",
        externalLink: true,
      },
    ],
  },
  {
    id: 12,
    iconClassName: "icon-superadmin",
    label: "Super Admin",
    path: "",
    children: [
      {
        label: "Order Summary",
        path: "/NPDB/AdminOrderSummary",
        externalLink: true,
      },
      {
        label: "Import Exclusion Data",
        path: routesNames.importExclusionData,
      },
      {
        label: "Network Partner",
        path: "/NetworkPartner/Index",
        externalLink: true,
      },
      {
        label: "Usage Report",
        path: "/UsageReport/Index",
        externalLink: true,
      },
      {
        label: "Assessment",
        path: "/Assessment/Index",
        externalLink: true,
      },
      {
        label: "Email Templates",
        path: "/EmailTemplate/Index",
        externalLink: true,
      },
      {
        label: "Expiry Report",
        path: "/UsageReport/ExpiryReport",
        externalLink: true,
      },
      {
        label: "Upload OIG",
        path: "/UsageReport/UploadOIG",
        externalLink: true,
      },
      {
        label: "BHAG Metrics",
        path: "/AdminReports/BHAGReport",
        externalLink: true,
      },
      {
        label: "Package Pricing",
        path: "/ServicePackage/Index",
        externalLink: true,
      },
      {
        label: "Productivity Report",
        path: "/UsageReport/EnrollOverallReport",
        externalLink: true,
      },
      {
        label: "Client Summary Report",
        path: "/UsageReport/ClientSummaryReport",
        externalLink: true,
      },
      {
        label: "Global Payer",
        path: "/Payer/GlobalPayer",
        externalLink: true,
      },
      {
        label: "Onboarding Report",
        path: "/PPRepository/DocuSignChargeReport",
        externalLink: true,
      },
      {
        label: "Incident Tracking",
        path: "/IncidentTracking/TicketDashboardSA",
        externalLink: true,
      },
      {
        label: "Incident Report",
        path: "/UsageReport/IncidentReport",
        externalLink: true,
      },
      {
        label: "Days In Workflow",
        path: "/AdminReports/WorkflowReport",
        externalLink: true,
      },
      {
        label: "DocuSign Report",
        path: routesNames.docuSignReport,
      },
    ],
  },
];

export default sidebarItems;
