import axiosClient from "@store/axiosClient";
import toastTypes from "@constants/toast-types";
import { toastMessageActions } from "@features/ui/ToastMessage";
import { staffActions } from "@features/clinicalAndNonClinicalStaff/ClinicalAndNonClinicalStaff";
import { IStaffSearchAndFilterParams } from "@helpers/interfaces/clinicalAndNonClinicalstaff";
import { handleServerInteractionError } from "@utilities/services";
import { checkIfAllLocationAndDepartmentSelected } from "@utilities/index";

function showLoading(dispatch: any) {
  dispatch(staffActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(staffActions.updateLoading(false));
}

export const getAllStaffList =
  (
    pageNumber: number,
    pageSize: number,
    filtersData: IStaffSearchAndFilterParams,
    providerType: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const {
          staffType,
          search,
          staffStatus,
          locations,
          department,
          sortColumn,
          sortBy,
          facilityIndicator,
        } = filtersData;

        const sortingEnabled = sortColumn && sortBy ? true : false;

        const sorting =
          sortColumn && !sortBy
            ? JSON.stringify({ SortColumn: sortColumn, SortOrder: sortBy })
            : "{}";

        const filteredLocations = locations.filter(
          (location) => location.selected
        );

        const filteredDepartments = department.filter(
          (department) => department.selected
        );

        const locationData = checkIfAllLocationAndDepartmentSelected(
          locations,
          filteredLocations
        );

        const departmentData = checkIfAllLocationAndDepartmentSelected(
          department,
          filteredDepartments
        );

        let url = `api/Employee/GetClinicalAndNonClinicalPageable?PageNumber=${pageNumber}&PageSize=${pageSize}&ProviderType=${providerType}&IsActive=${staffType}&EmployeeStatus=${staffStatus}&FacilityIndicator=${facilityIndicator}&IsSortingRequired=${sortingEnabled}&Sorting=${sorting}`;

        if (search) {
          url += `&Search=${search}`;
        }

        if (locationData) {
          url += `&Locations=${locationData}`;
        }

        if (departmentData) {
          url += `&Departments=${departmentData}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(staffActions.updateStaffList(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getLicensedProviderReport = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `api/Employee/GetExportRoaster`;

      const response = await axiosClient.get(url);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        resolve(response);
        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER,
        })
      );
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getLicensedProviderTermReport = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `api/Employee/GetTermExport`;

      const response = await axiosClient.get(url);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        resolve(response);
        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER,
        })
      );
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};
