import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "@hooks/index";
import { setDefaultDashboardInitialAndReCredFilterData } from "@helpers/configMiddlewares/employeeFiles";
import {
  IDashboardInitialAndReCredDataModel,
  IDashboardInitialAndReCredSearchAndFilterParams,
  IEmployeeModel,
} from "@helpers/interfaces/employeeFiles";
import { IDashboardInitialAndReCredPaginatedState } from "@helpers/uiInterfaces/employeeFiles";
import {
  getAllDashboardInitialList,
  getAllDashboardReCredList,
  getEmployeeData,
  loadDashboardInitialAndReCredAdvancedSearchList,
} from "@services/employeeFiles";
import { gapsInCredentialingUIActions } from "@features/ui/GapsInCredentialingUI";
import ContentHeader from "@common/ContentHeader";
import Spinner from "@common/spinner/Spinner";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import ProfileAvatar from "@common/profile-avatar/ProfileAvatar";
import DashboardInitialAndReCredFilterEditor from "./DashboardInitialAndReCredFilterEditor";
import DashboardInitialAndReCredSelectedFilters from "./DashboardInitialAndReCredSelectedFilters";
import {
  parseDashboardInitialAndReCredList,
  parseDashboardInitialReCredListFiltersCredOrganizationData,
  parseDashboardInitialReCredListFiltersProcessingTypeData,
  parseEmployeeListFiltersDepartmentData,
  parseEmployeeListFiltersLocationData,
} from "./utilities";
import styles from "./DashboardInitialAndReCred.module.scss";
import { dashboardInitialInstructions } from "@constants/instructions/dashboard-initial";
import dashboardInitialAndReCredListColumns from "@constants/json-data/table-columns/dashboard-initial/dashboardInitialAndReCredListColumns";
import {
  getLocationData,
  getOrganizationsByNetworkId,
} from "@services/master/master.services";
import { getAllDepartmentsListAtOnce } from "@services/departments/Department";
import { authenticationActions } from "@features/authentication/Authentication";
import { setApplicationStorage } from "@utilities/storage";
import { useNavigate } from "react-router";
import AddEditEmployeeEditor from "@pages/employeeFiles/AddEditEmployeesEditor/Emp_AddEditEditor";
import NoContentSection from "@common/no-content-section/NoContentSection";
import { dashboardReCredInstructions } from "@constants/instructions/dashboardReCredInstructions";
import EmployeesGapsInCredEditor from "@pages/employeeFiles/EmployeesGapsInCredEditor";
import routesNames from "@constants/routes-names";
import SearchField from "@common/search-field/SearchField";
import rushReqOptions from "@constants/json-data/masters-data/request-type-options";

const DEFAULT_PAGE_LIMIT = 10;

const DashboardInitialAndReCred = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const dashboardInitialAndReCredState = useAppSelector(
    (state) => state.employeeFiles.employee
  );
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const dashboardInitialAndReCredList =
    window.location.pathname === "/dashboard-initial"
      ? dashboardInitialAndReCredState.dashboardInitialDataList?.page
      : dashboardInitialAndReCredState.dashboardReCredDataList?.page;

  const [rootState, setRootState] =
    useState<IDashboardInitialAndReCredPaginatedState>({
      page: 1,
      limit: DEFAULT_PAGE_LIMIT,
      filterData: setDefaultDashboardInitialAndReCredFilterData(
        window.location.pathname === "/dashboard-initial" ? "initial" : "recred"
      ),
    });
  const [displayAddEditEmployeeEditor, setDisplayAddEditEmployeeEditor] =
    useState(false);
  const [selectedData, setSelectedData] = useState<IEmployeeModel | null>(null);
  const [displayFilterEditor, setDisplayFilterEditor] = useState(false);
  const [displayAlertsEditor, setDisplayAlertsEditor] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const loadDashboardInitialAndReCredList = useCallback(
    async (
      page: number,
      limit: number,
      filterData: IDashboardInitialAndReCredSearchAndFilterParams,
      advancedSearch: boolean
    ) => {
      if (advancedSearch) {
        const _filterData = {
          employeeType: filterData.employeeType,
          associatedCredentialer: filterData.associatedCredentialer,
          assignedDataAnalyst: filterData.assignedDataAnalyst,
          assignedManager: filterData.assignedManager,
          requestCredType: filterData.requestCredType,
          processingType: filterData.processingType,
          credJobStatus: filterData.credJobStatus,
          credOrganizationId: filterData.credOrganizationId,
          startDate: filterData.startDate,
          endDate: filterData.endDate,
        };
        await dispatch(
          loadDashboardInitialAndReCredAdvancedSearchList(
            page,
            limit,
            _filterData,
            window.location.pathname === "/dashboard-initial" ? true : false
          )
        );
        setDisplayFilterEditor(false);

        setRootState({
          ...rootState,
          page,
          limit,
          filterData,
        });
      } else {
        if (window.location.pathname === "/dashboard-initial")
          await dispatch(getAllDashboardInitialList(page, limit, filterData));
        else await dispatch(getAllDashboardReCredList(page, limit, filterData));
        setDisplayFilterEditor(false);

        setRootState({
          ...rootState,
          page,
          limit,
          filterData,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, window.location.pathname]
  );

  const loadDependencies = useCallback(async () => {
    const locations = await dispatch(getLocationData());
    const departments = await dispatch(getAllDepartmentsListAtOnce());
    const filterData = setDefaultDashboardInitialAndReCredFilterData(
      window.location.pathname === "/dashboard-initial" ? "initial" : "recred"
    );
    const credOrganizationObject = await dispatch(
      getOrganizationsByNetworkId(40)
    );
    const credOrgIds = credOrganizationObject.data?.value || [];

    filterData.locations = parseEmployeeListFiltersLocationData(
      locations.data,
      filterData.locations
    );
    filterData.department = parseEmployeeListFiltersDepartmentData(
      departments.data,
      filterData.department
    );
    filterData.credOrganizationId =
      parseDashboardInitialReCredListFiltersCredOrganizationData(
        credOrgIds,
        filterData.credOrganizationId
      );
    filterData.processingType =
      parseDashboardInitialReCredListFiltersProcessingTypeData(
        rushReqOptions,
        filterData.processingType
      );

    if (
      locations.status === 200 &&
      departments.status === 200 &&
      credOrganizationObject.status === 200
    ) {
      loadDashboardInitialAndReCredList(
        1,
        DEFAULT_PAGE_LIMIT,
        filterData,
        isAdvancedSearch
      );
    }

    setRootState((_rootState) => {
      return {
        ..._rootState,
        filterData,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadDashboardInitialAndReCredList]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  function closeAlertsEditor() {
    setDisplayAlertsEditor(false);
    dispatch(gapsInCredentialingUIActions.resetState());
  }

  function openAlertsEditor(employee: IDashboardInitialAndReCredDataModel) {
    const sliceState = {
      enable: true,
      hrmrUserId: employee.hrmrUserId,
      employeeName: `${employee.firstName} ${employee.lastName}`,
    };
    setDisplayAlertsEditor(true);
    dispatch(
      gapsInCredentialingUIActions.updateGapsInCredentialingState(sliceState)
    );
  }

  function renderAlertsEditor() {
    if (displayAlertsEditor === false) {
      return;
    }

    const employeeGapsInCredEditorAttributes = {
      open: displayAlertsEditor,
      onClose: closeAlertsEditor,
    };

    return (
      <EmployeesGapsInCredEditor {...employeeGapsInCredEditorAttributes} />
    );
  }

  function handleSearchFieldControl(searchText: string = "") {
    setRootState((prevState) => ({
      ...prevState,
      filterData: {
        ...prevState.filterData,
        search: searchText,
      },
    }));
    const filterData = { ...rootState.filterData, search: searchText };
    loadDashboardInitialAndReCredList(
      rootState.page,
      rootState.limit,
      filterData,
      isAdvancedSearch
    );
  }

  function renderSearchFieldControl() {
    const searchFieldControlAttributes = {
      placeholder: "Search by First or Last Name",
      value: rootState.filterData.search,
      onSearch: handleSearchFieldControl,
      onClear: handleSearchFieldControl,
      onChange(searchText: string) {},
    };

    return <SearchField {...searchFieldControlAttributes} />;
  }

  function renderHeader() {
    const searchAndFilterControlAttributes = {
      className: `btn btnorg float-end mx-1 my-2`,
      onClick() {
        setDisplayFilterEditor(true);
      },
    };

    const advancedSearchControlAttributes = {
      className: `btn btnorg float-end mx-1 my-2`,
      onClick() {
        setIsAdvancedSearch(true);
        setDisplayFilterEditor(true);
      },
    };

    const selectedFiltersAttributes = {
      filters: rootState.filterData,
      onFilterChange(filters: IDashboardInitialAndReCredSearchAndFilterParams) {
        loadDashboardInitialAndReCredList(
          1,
          rootState.limit,
          filters,
          isAdvancedSearch
        );
      },
    };

    return (
      <div className={`row align-items-center ${styles.rowContanier} mb-3`}>
        <div className="col-md-5 col-xs-12 col-sm-12 d-flex">
          <DashboardInitialAndReCredSelectedFilters
            {...selectedFiltersAttributes}
          />
        </div>
        <div className={`col-md-7 col-xs-12 col-sm-12 ${styles.btnContainer}`}>
          {renderSearchFieldControl()}
          <button {...searchAndFilterControlAttributes}>Search & Filter</button>
          <button {...advancedSearchControlAttributes}>Advanced Search</button>
        </div>
      </div>
    );
  }

  const handleOpenEmployeeFilesEditor = async (hrmrUserId: number) => {
    const response = await dispatch(getEmployeeData(hrmrUserId));
    if (response.status === 200) {
      setDisplayAddEditEmployeeEditor(true);
      setSelectedData(response.data);
    }
  };

  function renderTableActionElements(
    data: IDashboardInitialAndReCredDataModel
  ) {
    const _userRecord = { ...userRecord, hrmrUserId: data.hrmrUserId };

    const employeeFilesControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        handleOpenEmployeeFilesEditor(data.hrmrUserId);
      },
    };

    const credProfileControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(routesNames.credentialingProfileRoute);
      },
    };

    const npdbControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(routesNames.npdbRoute);
      },
    };

    const psvControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(routesNames.psvRoute);
      },
    };

    const ordersControlAttributes = {
      className: "table-link-button",
      onClick() {
        dispatch(authenticationActions.updateUserRecord(_userRecord));
        setApplicationStorage(_userRecord);
        navigate(`/orderSummary/${data.hrmrUserId}`);
      },
    };

    return (
      <div className={styles.actionElements}>
        <button {...employeeFilesControlAttributes}>Provider File</button> |{" "}
        <button {...credProfileControlAttributes}>Cred Profile</button> |{" "}
        <button {...npdbControlAttributes}>NPDB</button> |{" "}
        <button {...psvControlAttributes}>PSV</button> |{" "}
        <button {...ordersControlAttributes}>Orders</button>
      </div>
    );
  }

  function renderEmployeeProfilePicture(
    employee: IDashboardInitialAndReCredDataModel
  ) {
    const employeeFullName = `${employee.firstName?.trim()} ${employee.lastName?.trim()}`;
    const profilePicUrl = `${process.env.REACT_APP_CUSTOM_IMAGE_PATH}/${employee.profilePicture}`;

    const profileAvatarAttributes = {
      name: employeeFullName,
      profileURL: profilePicUrl,
      isProfileImageExists: employee.profilePicture ? true : false,
      dynamicColor: true,
      className: styles.profileAvatar,
    };

    return <ProfileAvatar {...profileAvatarAttributes} />;
  }

  function renderAlertControl(employee: IDashboardInitialAndReCredDataModel) {
    let contentNode = (
      <label className={styles.alertLabelNode}>No alerts</label>
    );

    if (employee.totalExpDocsCount !== 0) {
      const alertIconAttributes = {
        className: styles.alertIcon,
        icon: faCircleExclamation,
      };

      contentNode = (
        <label className={styles.alertLabelNode}>
          {employee.totalExpDocsCount} alerts{" "}
          <FontAwesomeIcon {...alertIconAttributes} />
        </label>
      );
    }

    const alertControlAttributes = {
      className: "table-link-button",
      onClick() {
        openAlertsEditor(employee);
      },
    };

    return <button {...alertControlAttributes}>{contentNode}</button>;
  }

  function renderSendReminderControl(
    employee: IDashboardInitialAndReCredDataModel
  ) {
    const sendReminderControlAttributes = {
      title: "Send Reminder",
      className: styles.sendReminderControl,
      onClick() {},
    };

    return (
      <button {...sendReminderControlAttributes}>
        <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
      </button>
    );
  }

  function renderAddEditEmployeeEditor() {
    if (displayAddEditEmployeeEditor === false) {
      return;
    }

    const employeeEditorAttributes = {
      data: selectedData,
      open: displayAddEditEmployeeEditor,
      isTraining: false,
      onClose() {
        setDisplayAddEditEmployeeEditor(false);
        setSelectedData(null);
      },
      updateSelectedData() {},
    };

    return <AddEditEmployeeEditor {...employeeEditorAttributes} />;
  }

  function renderContent() {
    if (
      !dashboardInitialAndReCredList ||
      dashboardInitialAndReCredState.loading
    ) {
      return <Spinner />;
    }

    if (dashboardInitialAndReCredList.length === 0) {
      return <NoContentSection />;
    }

    const parsedEmployees = parseDashboardInitialAndReCredList(
      dashboardInitialAndReCredList,
      renderEmployeeProfilePicture,
      renderAlertControl,
      renderSendReminderControl
    );

    const paginatedTableAttributes = {
      columns: dashboardInitialAndReCredListColumns,
      rows: parsedEmployees,
      actionElement: renderTableActionElements,
      currentPage: rootState.page,
      totalRecord:
        window.location.pathname === "/dashboard-initial"
          ? dashboardInitialAndReCredState.dashboardInitialDataList?.paging
              ?.totalItems
          : dashboardInitialAndReCredState.dashboardReCredDataList?.paging
              ?.totalItems,
      rowsPerPage: rootState.limit,
      onPageLimitChange(limit: number) {
        loadDashboardInitialAndReCredList(
          1,
          limit,
          rootState.filterData,
          isAdvancedSearch
        );
      },
      onPageChange(page: number) {
        loadDashboardInitialAndReCredList(
          page,
          rootState.limit,
          rootState.filterData,
          isAdvancedSearch
        );
      },
    };

    return <PaginatedTable {...paginatedTableAttributes} />;
  }

  function renderFiltersEditor() {
    if (displayFilterEditor === false) {
      return;
    }

    const dashboardInitialFiltersEditorAttributes = {
      open: displayFilterEditor,
      filterData: rootState.filterData,
      isAdvancedSearch: isAdvancedSearch,
      setIsAdvancedSearch: setIsAdvancedSearch,
      onSearch(
        data: IDashboardInitialAndReCredSearchAndFilterParams,
        advancedSearch: boolean
      ) {
        loadDashboardInitialAndReCredList(
          rootState.page,
          rootState.limit,
          data,
          advancedSearch
        );
      },
      onClose() {
        setDisplayFilterEditor(false);
      },
    };

    return (
      <DashboardInitialAndReCredFilterEditor
        {...dashboardInitialFiltersEditorAttributes}
      />
    );
  }

  const contentHeaderAttributes = {
    title:
      window.location.pathname === "/dashboard-initial"
        ? "Dashboard Initial"
        : "Dashboard Re-Cred",
    instructions:
      window.location.pathname === "/dashboard-initial"
        ? dashboardInitialInstructions
        : dashboardReCredInstructions,
  };

  return (
    <div id={styles.dashboardInitialAndReCredMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div
        className={`container-fluid ${styles.dashboardInitialAndReCredContainer}`}
      >
        {renderHeader()}
        {renderContent()}
        {renderFiltersEditor()}
        {renderAddEditEmployeeEditor()}
        {renderAlertsEditor()}
      </div>
    </div>
  );
};

export default DashboardInitialAndReCred;
