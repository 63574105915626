import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IMastersDataModel,
  IMedicalSpecialtyTaxonomyModel,
  IOrganizationOptionsDataModel,
  IProviderDataModel,
} from "@helpers/interfaces/masters/Masters";
import { IOrganizationTypeCodeControlState } from "@helpers/uiInterfaces/common";

const initialState = {
  countries: [] as Array<IMastersDataModel>,
  states: [] as Array<IMastersDataModel>,
  cities: [] as Array<IMastersDataModel>,
  organizations: {} as IOrganizationOptionsDataModel,
  lstCity: [] as Array<IMastersDataModel>,
  schoolCodes: [] as Array<IMastersDataModel>,
  providerTypes: [] as Array<IMastersDataModel>,
  specialty: [] as Array<IMastersDataModel>,
  locationData: [] as Array<IMastersDataModel>,
  providerTitle: [] as Array<IProviderDataModel>,
  licenseStatusCodes: [] as Array<IMastersDataModel>,
  licenseFieldCodes: [] as Array<IMastersDataModel>,
  provideLanguageCode: [] as Array<IMastersDataModel>,
  appVerification: [] as Array<IMastersDataModel>,
  organizationalTitles: [] as Array<IMastersDataModel>,
  medicalSpecialtyTaxonomyData: [] as Array<IMedicalSpecialtyTaxonomyModel>,
  allEmployeeStatus: [] as Array<IMastersDataModel>,
  allVendors: [] as Array<IMastersDataModel>,
  allPrivateAssignCategory: [] as Array<IMastersDataModel>,
  yearOptions: [] as Array<IMastersDataModel>,
  organizationTypeCodes: [] as Array<IOrganizationTypeCodeControlState>,
  allNetworkByOrganizationId: [] as Array<IMastersDataModel>,
  allNetworkServiceSiteByOrganizationId: [] as Array<IMastersDataModel>,
  estimatedWaitTimeId: [] as Array<IMastersDataModel>,
  propertyListByPropertyType: [] as Array<IMastersDataModel>,
  visitType: [] as Array<IMastersDataModel>,
  multipleCities: [] as Array<IMastersDataModel>,
  allCredentialerUserList: [] as Array<IMastersDataModel>,
  allRequestPrivilegingList: [] as Array<IMastersDataModel>,
  affiliationByOrgIdList: [] as Array<IMastersDataModel>,
  privilegingCategoryListByRequestPrivIds: [] as Array<IMastersDataModel>,
  counties: [] as Array<IMastersDataModel>,
};

const mastersSlice = createSlice({
  name: "masters",
  initialState,
  reducers: {
    updateCountries: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.countries = action.payload;
    },

    updateStates: (state, action: PayloadAction<Array<IMastersDataModel>>) => {
      state.states = action.payload;
    },

    updateCities: (state, action: PayloadAction<Array<IMastersDataModel>>) => {
      state.cities = action.payload;
    },

    updateOrganizations: (
      state,
      action: PayloadAction<IOrganizationOptionsDataModel>
    ) => {
      state.organizations = action.payload;
    },

    getCityState: (state, { payload }) => {
      state.lstCity = payload;
    },

    updateSchoolCodes: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.schoolCodes = action.payload;
    },

    updateProviderTypes: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.providerTypes = action.payload;
    },

    updateSpecialty: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.specialty = action.payload;
    },

    updateLocationData: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.locationData = action.payload;
    },

    updateProviderTitle: (
      state,
      action: PayloadAction<Array<IProviderDataModel>>
    ) => {
      state.providerTitle = action.payload;
    },

    updateLicenseStatusCodes: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.licenseStatusCodes = action.payload;
    },

    updateLicenseFieldCodes: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.licenseFieldCodes = action.payload;
    },

    updateProviderLanguageCode: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.provideLanguageCode = action.payload;
    },

    updateAppVerification: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.appVerification = action.payload;
    },

    updateOrganizationalTitles: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.organizationalTitles = action.payload;
    },

    updateMedicalSpecialtyTaxonomyData: (
      state,
      action: PayloadAction<Array<IMedicalSpecialtyTaxonomyModel>>
    ) => {
      state.medicalSpecialtyTaxonomyData = action.payload;
    },

    updateAllEmployeeStatus: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allEmployeeStatus = action.payload;
    },

    updateAllVendorsData: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allVendors = action.payload;
    },

    updateAllPrivateAssignCategory: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allPrivateAssignCategory = action.payload;
    },

    updateYearOptions: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.yearOptions = action.payload;
    },

    updateOrganizationtypeCodes: (
      state,
      action: PayloadAction<Array<IOrganizationTypeCodeControlState>>
    ) => {
      state.organizationTypeCodes = action.payload;
    },

    updateAllNetworkByOrganizationId: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allNetworkByOrganizationId = action.payload;
    },

    updateAllNetworkServiceSiteByOrganizationId: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allNetworkServiceSiteByOrganizationId = action.payload;
    },

    updateLevelOfExperience: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.propertyListByPropertyType = action.payload;
    },

    updateCitiesByStates: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.multipleCities = action.payload;
    },

    updateAllCredentialerUserList: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allCredentialerUserList = action.payload;
    },

    updateAllRequestPrivilegingList: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.allRequestPrivilegingList = action.payload;
    },

    updateAffiliationByOrgIdList: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.affiliationByOrgIdList = action.payload;
    },

    updatePrivilegingCategoryListByRequestPrivIds: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.privilegingCategoryListByRequestPrivIds = action.payload;
    },

    updateCounties: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.counties = action.payload;
    },
  },
});

export const mastersActions = mastersSlice.actions;
export default mastersSlice.reducer;
