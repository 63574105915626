import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import {
  IPracLocCoveringColleaguesModel,
  IPracticeLocationModel,
} from "@helpers/interfaces/credentialProfile/PracticeLocationInfoTab";

export function setDefaultPracticeLocationInfo(
  userRecord: IUserRecordModel
): IPracticeLocationModel {
  const data = {
    practiceLocationId: 0,
    hrmrUserId: userRecord.hrmrUserId,
    isPrimary: true,
    isActive: false,
    address: "",
    practicingAddress: true,
    expectedStartDate: null,
    ifYesExpectedStartDate: null,
    physicianGroup: "",
    groupCorporateName: "",
    number: "",
    street: "",
    building: "",
    country: 0,
    state: 0,
    city: 0,
    zipCode: "",
    correspondenceHere: false,
    telephone: "",
    fax: "",
    officeEmail: "",
    individualTaxId: "",
    groupTaxId: "",
    organizationNPI: "",
    useIndividualTaxId: false,
    useGroupTaxId: true,
    typeofServiceProvided: "",
    doYouHaveCLIACertificate: false,
    doYouHaveCLIAWaiver: false,
    certificateNumber: "",
    certificateExpirationDate: null,
    locationId: 0,
    parentLocationId: 0,
    isFillinLocation: false,
    isOutreachLocation: false,
    estimatedWaitTimeId: 0,
    isElectronicPrescribing: false,
    isReferralRequired: false,
    specificPopulationTypes: "",
    isPersonTelehealthVisits: false,
    telehealthThirdPartyEntity: "",
    homeVisitStateIds: "",
    homeVisitCityIds: "",
    visitTypeIDs: "",
    isAcceptNewPatientAtLocation: true,
    expectedEndDate: null,
    county: 0,
    isNetwork: false,
  };

  return data;
}

export function setDefaultCoveringColleagues(
  userRecord: IUserRecordModel
): IPracLocCoveringColleaguesModel {
  const data = {
    coveringColleaguesId: 0,
    hrmrUserId: userRecord.hrmrUserId,
    lastName: "",
    firstName: "",
    mi: "",
    spaciltyCode: "",
    providerType: "",
  };

  return data;
}
