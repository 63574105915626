import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import { IReportingDataModel } from "@helpers/interfaces/employeeFiles";
import { IOrganizationModel } from "@helpers/interfaces/masters/Masters";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";
import moment from "moment";

export function parseReportingList(
  reportingList: Array<IReportingDataModel>,
  renderEmployeeProfilePicture: (reportingData: IReportingDataModel) => void,
  renderAlertControl: (reportingData: IReportingDataModel) => void,
  renderSendReminderControl: (reportingData: IReportingDataModel) => void
) {
  const _reportingList = [];

  for (let reportingData of reportingList) {
    const _reportingData = {
      ...reportingData,
      createDate: reportingData.createdDate
        ? moment(reportingData.createdDate).format("MM/DD/YYYY")
        : "",
      renderEmployeeProfilePicture: renderEmployeeProfilePicture(reportingData),
      renderAlertsControl: renderAlertControl(reportingData),
      sendReminderControl: renderSendReminderControl(reportingData),
    };

    _reportingList.push(_reportingData);
  }

  return _reportingList;
}

export function parseReportingListFiltersCredOrganizationData(
  organizations: Array<IOrganizationModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _organizations: Array<ICheckboxSelectDataStructure> = [];

  for (let organization of organizations) {
    const object: ICheckboxSelectDataStructure = {
      label: organization.organizationName,
      value: String(organization.organizationId),
      selected: true,
    };

    _organizations.push(object);
  }

  return _organizations;
}

export const parseReportingListFiltersProcessingTypeData = (
  processingTypes: Array<any>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) => {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _processingTypes: Array<ICheckboxSelectDataStructure> = [];

  for (let processingType of processingTypes) {
    const object: ICheckboxSelectDataStructure = {
      label: processingType.label,
      value: String(processingType.value),
      selected: true,
    };

    _processingTypes.push(object);
  }

  return _processingTypes;
};

export function createReportingDatasetReport(
  data: Array<any>,
  processingTypeList: Array<any>,
  isTntReport: boolean
) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    if (isTntReport) {
      const instanceCreateDate = item.instanceCreateDate
        ? item.instanceCreateDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.instanceCreateDate).format("MM/DD/YYYY")
        : "";
      const appSentDate = item.appSentDate
        ? item.appSentDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.appSentDate).format("MM/DD/YYYY")
        : "";
      const appRecievedDate = item.appReceivedDate
        ? item.appReceivedDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.appReceivedDate).format("MM/DD/YYYY")
        : "";
      const appInReviewDate = item.appInReviewDate
        ? item.appInReviewDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.appInReviewDate).format("MM/DD/YYYY")
        : "";
      const appCompleteDate = item.appCompleteDate
        ? item.appCompleteDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.appCompleteDate).format("MM/DD/YYYY")
        : "";
      const psvinProcessDate = item.pSVInProcessDate
        ? item.pSVInProcessDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.pSVInProcessDate).format("MM/DD/YYYY")
        : "";
      const ecrCompletedDate = item.eCRCompletedFileInReviewDate
        ? item.eCRCompletedFileInReviewDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.eCRCompletedFileInReviewDate).format("MM/DD/YYYY")
        : "";
      const credentialingCompletedDate = item.credentialingCompletedDate
        ? item.credentialingCompletedDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.credentialingCompletedDate).format("MM/DD/YYYY")
        : "";
      const sentForBoardReviewDate = item.sentForBoardReviewDate
        ? item.sentForBoardReviewDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.sentForBoardReviewDate).format("MM/DD/YYYY")
        : "";
      const credentialingApprovedDate = item.credentialingApprovedDate
        ? item.credentialingApprovedDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.credentialingApprovedDate).format("MM/DD/YYYY")
        : "";
      const _item = {
        "UID": item.uid,
        "First Name": item.firstName,
        "Last Name": item.lastName,
        "Credential Name": item.credentialsName,
        "Organization Link": item.organizationName,
        "Instance ID": item.credentialingTrackingId,
        "Instance Created Date": instanceCreateDate,
        "App Sent Date": appSentDate,
        "App Recieved Date": appRecievedDate,
        "App In Review Date": appInReviewDate,
        "App Complete (Clean App) Date": appCompleteDate,
        "PSV In Process Date": psvinProcessDate,
        "ECR - Completed file in review Date": ecrCompletedDate,
        "Credentialing Completed and Submitted to Client Date":
          credentialingCompletedDate,
        "Red Flags - Sent for Board Review": sentForBoardReviewDate,
        "Credentialing Approved": credentialingApprovedDate,
        "TAT Credentialing Completed [m-j]": item.completedTAN,
        "TAT Credentialing Approved [o-j]": item.approvedTAN,
      };

      _data.push(_item);
    } else {
      const originalCredentialingDate = item.originalCredentialingDate
        ? item.originalCredentialingDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.originalCredentialingDate).format("MM/DD/YYYY")
        : "";
      const fromDate = item.fromDate
        ? item.fromDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.fromDate).format("MM/DD/YYYY")
        : "";
      const toDate = item.toDate
        ? item.toDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.toDate).format("MM/DD/YYYY")
        : "";
      const terminationDate = item.terminationDate
        ? item.terminationDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(item.terminationDate).format("MM/DD/YYYY")
        : "";

      const _item = {
        "UID": item.uid,
        "NPI": item.npi,
        "First Name": item.firstName,
        "Middle Name": item.middleName,
        "Last Name": item.lastName,
        "Credential Name": item.credentialsName,
        "Email": item.email,
        "Alternate Email": item.alternateEmail,
        "Cred Primary Email": item.credPrimaryEmail,
        "Cred Secondary Email": item.credSecondaryEmail,
        "Instance ID": item.credentialingTrackingId,
        "Credentialing Job Status": item.credentialingJobStatus,
        "Organization Name": item.organizationName,
        "Original Credentialing Date": originalCredentialingDate,
        "From Date": fromDate,
        "To Date": toDate,
        "Organization Status": item.organizationStatus
          ? "Active"
          : "Terminated",
        "Termination Date": terminationDate,
        "Termination Reason": item.reasonForTermination,
        "Cred manager": item.assignedManager,
        "Cred Analyst": item.associatedCredentialer,
        "Cred Data Analyst": item.assignedDataAnalysist,
        "Cred Type":
          reqCredTypeOptions.find((data) => data.value === item.requestCredType)
            ?.label || "",
        "Processing Type":
          processingTypeList.find((data) => data.value === item.processingType)
            ?.label || "",
      };

      _data.push(_item);
    }
  });

  return _data;
}
