import {
  ICredTrackingModel,
  ICredTrackOrgLinkModel,
  IDashboardInitialAndReCredDataModel,
  IDashboardInitialAndReCredSearchAndFilterParams,
  IEmployeeModel,
  IEmployeeSearchAndFilterParams,
  INotificationReminderAlertModel,
  IPrivilegingModel,
  IReportingSearchAndFilterParams,
} from "@helpers/interfaces/employeeFiles";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";

export function setDefaultEmployeeFilesFilterData(): IEmployeeSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    locations: [] as Array<ICheckboxSelectDataStructure>,
    department: [] as Array<ICheckboxSelectDataStructure>,
    employeeStatus: "All",
  };

  return data;
}

export function setDefaultEmployeeData(isFacility?: boolean): IEmployeeModel {
  const data = {
    hrmrUserId: 0,
    parentHRMRUserId: null,
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    employeeType: "",
    gender: "0",
    tempColExist_UserId: "",
    email: "",
    isActive: false,
    dob: null,
    credentialsId: isFacility ? 30 : 0,
    credentialsName: "",
    departmentId: 0,
    department: "",
    mobileNo: "",
    empAppTypePropId: 0,
    terminationDate: null,
    dateOfHire: null,
    alternateEmail: "",
    supervisor: 0,
    supervisorName: "",
    billingSupervisor: 0,
    billingSupervisorName: "",
    isUserName: "Email",
    userName: "",
    password: "",
    enableWebPortal: false,
    employeeStatus: "",
    emergencyContact: "",
    emergencyContactName: "",
    profilePicture: "",
    profilePicturePath: "",
    isMalpractice: false,
    comment: "",
    reasonForTermination: "",
    vendorId: "",
    dateAddedToWatchdog: null,
    employmentStartDate: null,
    totalCount: 0,
    totalExpDocsCount: 0,
    locations: "",
    pronouns: "0",
    credContactName: "",
    credPrimaryEmail: "",
    credSecondaryEmail: "",
    credPhone: "",
    credPhoneExt: "",
    credFax: "",
    credAddress: "",
    credCityId: 0,
    credStateId: 0,
    credZip: "",
    isSuppressInProviderDirectory: false,
    facilityIndicator: isFacility ? "2" : "1",
    facilityName: "",
    isNPDBReport: false,
    statusOfApplication: null,
    hospitalName: null,
    notificationSchedule: null,
    countByCredentials: 0,
    npiNumber: null,
    credCityName: null,
    credStateName: null,
    providerSpecialties: null,
    requestCredTypeName: null,
    workflowStartDate: null,
    workflowEndDate: null,
    credentialingTrackingId: 0,
    facilityIndicatorText: null,
    file: null,
  };

  return data;
}

export function setDefaultDashboardInitialAndReCredFilterData(
  mode: string
): IDashboardInitialAndReCredSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    locations: [] as Array<ICheckboxSelectDataStructure>,
    department: [] as Array<ICheckboxSelectDataStructure>,
    employeeStatus: "All",
    associatedCredentialer: 0,
    assignedDataAnalyst: 0,
    assignedManager: 0,
    requestCredType: mode === "initial" ? 1 : 2,
    processingType: [] as Array<ICheckboxSelectDataStructure>,
    credJobStatus: 0,
    credOrganizationId: [] as Array<ICheckboxSelectDataStructure>,
    startDate: "",
    endDate: "",
  };

  return data;
}

export function setDefaultDashboardInitialAndReCredData(): IDashboardInitialAndReCredDataModel {
  const data = {
    hrmrUserId: 0,
    tempColExist_UserId: "",
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    gender: "",
    email: "",
    isActive: false,
    dob: null,
    credentialsId: 0,
    credentialsName: "",
    departmentId: 0,
    department: "",
    mobileNo: "",
    empAppTypePropId: 0,
    terminationDate: null,
    dateOfHire: null,
    alternateEmail: "",
    supervisor: 0,
    supervisorName: "",
    billingSupervisor: 0,
    billingSupervisorName: "",
    isUserName: "",
    userName: "",
    enableWebPortal: false,
    employeeStatus: "",
    emergencyContact: "",
    emergencyContactName: "",
    profilePicture: "",
    profilePicturePath: "",
    isMalpractice: false,
    comment: "",
    reasonForTermination: "",
    vendorId: "",
    dateAddedToWatchdog: null,
    employmentStartDate: null,
    locations: "",
    employeeType: "",
    totalExpDocsCount: 0,
    totalCount: 0,
    isNPDBReport: false,
    statusOfApplication: "",
    hospitalName: "",
    notificationSchedule: "",
    createdBy: "",
    createdDate: null,
    updatedBy: "",
    updatedDate: null,
    userId: "",
    organizationId: "",
    countByCredentials: 0,
    npiNumber: null,
    pronouns: 0,
    credContactName: "",
    credPrimaryEmail: "",
    credSecondaryEmail: "",
    credPhoneExt: "",
    credPhone: "",
    credFax: "",
    credAddress: "",
    credCityId: 0,
    credStateId: 0,
    credCityName: "",
    credStateName: "",
    credZip: "",
    providerSpecialties: "",
    parentHRMRUserId: 0,
    isSuppressInProviderDirectory: false,
    requestCredTypeName: "",
    workflowStartDate: null,
    workflowEndDate: null,
    credentialingTrackingId: 0,
  };

  return data;
}

export function setDefaultCredTrackingData(): ICredTrackingModel {
  const data = {
    credentialingTrackingId: 0,
    hrmrUserId: 0,
    jobNo: null,
    associatedCredentialer: 0,
    requestCredType: 0,
    processingType: 0,
    credJobStatus: 0,
    workflowStartDate: null,
    workflowEndDate: null,
    beginningDate: null,
    endDate: null,
    insituitionRequesting: "",
    orgRequestOrgId: null,
    orgRequestUserId: null,
    comment: null,
    credTrackOrgIds: null,
    isHide: false,
    isDelete: false,
    associatedCredentialerName: null,
    organizationNames: null,
    totalCount: 0,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    userId: null,
    organizationId: null,
    assignedDataAnalysist: 0,
    assignedManager: 0,
    assignedManagerName: null,
    assignedDataAnalysistName: null,
    dateReportToClient: null,
    isCompleted: false,
    isEditEnalble: false,
    legacyStatus: "",
    category: "",
    originalAppointmentDate: null,
    dob: null,
    sendNotification: false,
    credTrackOrgLinkList: [],
    credTrackOrganizationLinkList: [],
    isOpenInstance: false,
  };

  return data;
}

export function setDefaultCredTrackingLogsData(): any {
  const data = {
    credTrackingLogId: 0,
    credentialingTrackingId: 0,
    hrmrUserId: 0,
    jobNo: "",
    associatedCredentialer: "",
    requestCredType: 0,
    processingType: 0,
    credJobStatus: 0,
    workflowStartDate: null,
    workflowEndDate: null,
    beginningDate: null,
    endDate: null,
    insituitionRequesting: 0,
    orgRequestOrgId: "",
    orgRequestUserId: "",
    comment: "",
    credTrackOrgIds: "",
    isHide: false,
    isDelete: false,
    organizationNames: "",
    associatedCredentialerName: "",
    totalCount: 0,
    createdBy: "",
    createdDate: null,
    updatedBy: "",
    updatedDate: null,
    userId: "",
    organizationId: "",
    dateReportToClient: null,
    isCompleted: false,
  };

  return data;
}

export function setDefaultOrgLinkData(): ICredTrackOrgLinkModel {
  const data = {
    credTrackingOrgLinkId: 0,
    credentialingTrackingId: 0,
    isActive: true,
    terminateDate: null,
    terminateReason: "",
    legacyStatus: "",
    category: "",
    originalAppointmentDate: null,
    createdBy: "",
    updatedBy: "",
    organizationId: "",
    organizationName: "",
    isDelete: false,
    isNew: true,
  };
  return data;
}

export function setDefaultPrivilegingData(): IPrivilegingModel {
  const data = {
    payerProviderId: 0,
    hrmrUserId: 0,
    credActivityId: 2,
    credActivity: "",
    credentialerUserId: "",
    payerId: 0,
    enrollmentStatus: "",
    originalCredentialingDate: null,
    enrollmentDate: null,
    enrollmentExpiryDate: null,
    userId: "",
    isDeleted: false,
    isHide: false,
    createdBy: "",
    createdDate: null,
    assignDate: null,
    requestingPrivilegingIds: "",
    privilegingCategoryIds: "",
    jobNo: null,
    credentialingTrackingId: 0,
    isPublicRecord: true,
    entityCategoryId: 0,
    updatedBy: "",
    updatedDate: null,
    documentTypeIds: "",
    documentType: "",
    documentTypeName: "",
    formFiles: null,
    instanceValue: "",
    payerProvidersDocuments: [],
  };

  return data;
}

export function setDefaultnotificationReminderAlertData(): INotificationReminderAlertModel {
  const data = {
    notificationReminderAlertSettingsId: 0,
    hrmrUserId: 0,
    isApplicationPasswordSentAlert: false,
    isCredAcceptedRequest: false,
    isApplicationReceivedAlert: false,
    isProfessionalReferencesAlert: false,
    isWorkHistoryAlert: false,
    isHospitalAffiliationsAlert: false,
    isProfessionalLiabilityInsuranceCarrierAlert: false,
    isUndergraduateSchoolAlert: false,
    isUsCanadianSchoolAlert: false,
    isTrainingDetailsAlert: false,
    createdDate: null,
    updatedDate: null,
    isHide: false,
    isRush: false,
    isRushModified: false,
    isNotificationsDisabled: false,
    reminderDays: 0,
  };

  return data;
}

export function setDefaultReportingFilterData(): IReportingSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    associatedCredentialer: 0,
    assignedDataAnalyst: 0,
    assignedManager: 0,
    requestCredType: [] as Array<ICheckboxSelectDataStructure>,
    processingType: [] as Array<ICheckboxSelectDataStructure>,
    credJobStatus: 0,
    credOrganizationId: [] as Array<ICheckboxSelectDataStructure>,
    startDate: "",
    endDate: "",
    orgCreatedFromDate: "",
    orgCreatedToDate: "",
    orgTerminationFromDate: "",
    orgTerminationToDate: "",
    orgStatus: "1",
    logCreationFromDate: "",
    logCreationToDate: "",
  };

  return data;
}
