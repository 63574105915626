import CryptoJS from "crypto-js";
import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";
import moment from "moment";
import "moment-timezone";
import {
  ICheckboxSelectDataStructure,
  IContactControlState,
  IDeptControlState,
  ILocationControlState,
  IOrganizationTypeCodeControlState,
} from "helpers/uiInterfaces/common";
import { IDocTypeByHrmrUserId } from "@helpers/interfaces/manageDocuments";
import { ICredJobStatus } from "@helpers/interfaces/trackingReport";

export function parseSelectControlsData(data: Array<ILocationControlState>) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.name,
      value: item.id,
    };

    _data.push(object);
  }

  return _data;
}

export function parseSelectControlsDataWithValueAndName(
  data: Array<IMastersDataModel>
) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.name,
      value: item.value,
    };

    _data.push(object);
  }

  return _data;
}

export function parseCredJobSelectControlsData(data: Array<ICredJobStatus>) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.jobCredStatus,
      value: item.jobCredStatusId,
    };

    _data.push(object);
  }

  return _data;
}

export function parseSelectOrganizationTypeCodeControlsData(
  data: Array<IOrganizationTypeCodeControlState>
) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.typeCodeDescription,
      value: item.typeCode,
    };

    _data.push(object);
  }

  return _data;
}

export function parseSelectDocTypeCodeControlsData(
  data: Array<IDocTypeByHrmrUserId>
) {
  const _data = [];

  for (let item of data) {
    const docName = item.appVerificationName
      ? `(${item.appVerificationName})`
      : "";

    const object = {
      label: `${item.activityName} - ${item.docType} ${docName}`,
      value: item.docTypeId,
    };

    _data.push(object);
  }

  return _data;
}

export function parseDeptSelectControlsData(data: Array<IDeptControlState>) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.departmentName,
      value: item.departmentId,
    };

    _data.push(object);
  }

  return _data;
}

export function checkObjectIsEmpty(object: Object) {
  if (Object.keys(object).length === 0 && object.constructor === Object) {
    return true;
  }

  return false;
}

export function getExtensionFromFileName(fileName: string | null) {
  if (fileName === null) {
    return null;
  }

  const extension = fileName.substring(
    fileName.lastIndexOf(".") + 1,
    fileName.length
  );

  return extension;
}

export function generateSelectedLanguageCodeJSON(
  selectedLanguage: string | null,
  languageCodes: Array<IMastersDataModel>
) {
  const _selectedLanguage: Array<any> = [];

  if (selectedLanguage === null) {
    return _selectedLanguage;
  }

  // converting '1,2,3' into [1, 2, 3]
  const tempArray = selectedLanguage.split(",").map((item) => +item);

  for (let index of tempArray) {
    for (let code of languageCodes) {
      if (index === +code.id) {
        const object = {
          label: code.name,
          value: code.id,
        };
        _selectedLanguage.push(object);
        continue;
      }
    }
  }

  return _selectedLanguage;
}

export function getCodeIdsString(codes: Array<any>) {
  if (codes.length > 0) {
    const _codes = codes.map((item) => item.value);
    return _codes.join(",");
  } else return "";
}

export const createIdsString = (ids: Array<string>) => {
  if (ids.length > 0) {
    const _ids = ids.map((id) => id);
    return _ids.join(",");
  } else return "";
};

export function generateSelectedGuidelineCodeJSON(
  selectedGuidelines: string | null,
  guidelines: Array<any>
) {
  const _selectedLanguage: Array<any> = [];

  if (selectedGuidelines === null) {
    return _selectedLanguage;
  }

  // converting '1,2,3' into [1, 2, 3]
  const tempArray = selectedGuidelines.split(",").map((item) => +item);

  for (let index of tempArray) {
    for (let code of guidelines) {
      if (index === +code.value) {
        const object = {
          label: code.label,
          value: code.value,
        };
        _selectedLanguage.push(object);
        continue;
      }
    }
  }
  return _selectedLanguage;
}

export function getLanguageCodeIdsString(languageCode: Array<any>) {
  const _languageCodes = languageCode.map((item) => item.value);
  return _languageCodes.join(",");
}

export const encryptValueIntoCipherValue = (value: any) => {
  const encryptionKey: string = process.env.REACT_APP_AES_ENCRYPTION_KEY!;
  let cipherText = CryptoJS.AES.encrypt(value, encryptionKey).toString();

  return cipherText;
};

export const decryptCipherValueIntoPlainValue = (data: any) => {
  const decryptionKey: string = process.env.REACT_APP_AES_ENCRYPTION_KEY!;
  let bytes = CryptoJS.AES.decrypt(data, decryptionKey);
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

// export function extractAvatarCharactersFromName(name: string) {
//   let avatarString = "";

//   name
//     .split(" ")
//     .slice(0, 2)
//     .map((item) => {
//       avatarString = avatarString + item[0];
//     });

//   return avatarString;
// }

export function extractAvatarCharactersFromName(name: string): string {
  return name
    .split(" ")
    .slice(0, 2)
    .map((item) => item[0])
    .join("");
}

export function generateRandomColorCodes() {
  const randomNumber = Math.floor(Math.random() * 16777215).toString(16);
  const hexColorCode = `#${randomNumber}`;
  return hexColorCode;
}

export function extractLocationOrDepartmentsIds(
  data: Array<ICheckboxSelectDataStructure>
) {
  const idsArray = [];

  for (let item of data) {
    idsArray.push(item.value);
  }

  return idsArray.join(",");
}

export function checkIfAllLocationAndDepartmentSelected(
  array: any,
  filtereredArray: any
) {
  if (array.length === filtereredArray.length) {
    return "All";
  } else return extractLocationOrDepartmentsIds(filtereredArray);
}

export function removeHtmlTagsAndExtractContent(htmlString: string) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  return tempElement.textContent || tempElement.innerText || "";
}

export const createCustomDomainUrl = (customDomain: string) => {
  const dynamicWord = customDomain;

  const originalUrl = process.env.REACT_APP_WATCHDOG_APP_URL;
  const urlParts = originalUrl?.split("//app");

  if (urlParts?.length === 2) {
    const newUrl = urlParts[0] + "//" + dynamicWord + urlParts[1];
    return newUrl;
  }
};

export const getSubDomain = (domain: string) => {
  const subDomain = domain.split(".");
  if (subDomain.length > 0) {
    return subDomain[0];
  } else {
    return "";
  }
};

export const isDateInPastOneMonth = (dateString: string) => {
  // Convert the date string to a JavaScript Date object
  const date: Date = new Date(dateString);

  // Get the current date
  const currentDate: Date = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds: number =
    currentDate.getTime() - date.getTime();

  // Calculate the difference in days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Check if the date is within the past one month range
  return differenceInDays <= 30;
};

export const removeSpacesAndDashes = (input: string) => {
  // Remove spaces and dashes using regular expressions
  if (input) {
    return input.replace(/[\s-]/g, "");
  } else {
    return "";
  }
};

export const isCustomDomain = (domain: string) => {
  if (
    domain === "stage" ||
    domain === "app" ||
    domain === "uatcompliancewatchdogreact" ||
    domain === "cwdevreact" ||
    domain === "cred"
  ) {
    return false;
  } else {
    return true;
  }
};

export const notApplicableDateFunc = (date: Date | string) => {
  if (moment(date).format("MM/DD/YYYY") === "01-01-0001") {
    return "Not Available";
  } else return moment(date).format("MM/DD/YYYY");
};

export const maskString = (inputString: string) => {
  if (inputString && inputString.length > 0) {
    // Generate a string of stars with the same length as the input string
    return "*".repeat(inputString.length);
  } else {
    return "";
  }
};

export const convertUTCToCST = (date: Date) => {
  const time = moment
    .utc(date)
    .tz("America/Chicago")
    .format("HH:mm:ss A [CST]");
  return time;
};

export const isValidDate = (dateStr: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateRegex.test(dateStr)) {
    return false;
  }

  const [year, month, day] = dateStr.split("-").map(Number);

  if (year <= 1900) {
    return false;
  }

  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};

export const getFutureDate = (years: number) => {
  // Get the current date
  let currentDate = new Date();

  // Get the current year, month, and day
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth();
  let currentDay = currentDate.getDate();

  // Calculate the future year
  let futureYear = currentYear + years;

  // Create a new date object with the future year
  let futureDate = new Date(futureYear, currentMonth, currentDay);

  // Return the future date as a string
  return moment(futureDate).format("YYYY-MM-DD");
};

export function parseSelectContactsControlsData(
  data: Array<IContactControlState>
) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.contactName,
      value: item.contactId,
    };

    _data.push(object);
  }

  return _data;
}

export const findLastIndexCustom = (
  arr: any[],
  predicate: (value: any, index: number, array: any[]) => boolean
) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i], i, arr)) {
      return i;
    }
  }
  return -1;
};

export const getLoginUrlAccordingToEnv = () => {
  let redirectUrl = "";

  // Check environment and set redirect URL accordingly
  if (process.env.NODE_ENV === "development") {
    redirectUrl = "http://localhost:3000/"; // URL for development
  } else if (process.env.NODE_ENV === "production") {
    redirectUrl = "https://app.compliancewatchdog.com/"; // URL for production
  } else if (process.env.NODE_ENV === "stage") {
    redirectUrl = "https://stage.compliancewatchdog.com/"; // URL for Stage
  } else {
    redirectUrl = "https://stage.compliancewatchdog.com/"; // URL for Stage
  }
  return redirectUrl;
};
