import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { IPerProf_PersonalInfoModel } from "@helpers/interfaces/credentialProfile/PerProfInfoTab";
import { setDefaultPersonalInfo } from "@helpers/configMiddlewares/credentialingProfile/perProfInfoTab";

import { personalInfoAction } from "@features/credentialProfile/perProfInfoTab/PersonalInfo";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleCreateServiceResponse, handleServerInteractionError } from "@utilities/services";
import { getApplicationStorage } from "@utilities/storage";

function showLoading(dispatch: any) {
  dispatch(personalInfoAction.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(personalInfoAction.updateLoading(false));
}

export const getPersonalInfo = (hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const userRecord = getApplicationStorage();
      const response = await axiosClient.get(`api/CredentialingProfile/GetPersonalInfo?HRMRUserId=${hrmrUserId}`);
      let data = response.data;
      const status = response.status;

      if (status === 200) {

        // this if block will run when statusCode is 200 and data has falsy value
        // e.g. user don't have existing record for personal info
        if (!data) {
          response.data = setDefaultPersonalInfo(userRecord);
          resolve(response);
        } else {
          dispatch(personalInfoAction.updatePersonalInfo(data));
          resolve(response);
        }

        return hideLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    }
    catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

};

export const createPersonalInfo = (data: IPerProf_PersonalInfoModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/CreatePersonalInfo",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleCreateServiceResponse(dispatch, personalInfoAction, status, response.data);

      hideLoading(dispatch);
      resolve(response);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }

  });
};