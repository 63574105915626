import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { handleServerInteractionError } from "@utilities/services";
import { psvActions } from "@features/psv/orderSummary";
import messages from "@constants/messages";

function showLoading(dispatch: any) {
  dispatch(psvActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(psvActions.updateLoading(false));
}

export const psvOrderConfirm =
  (
    hrmrUserId: number,
    isEducation: boolean,
    IsIntEduc: boolean,
    isLicense: boolean,
    isBackgroundCheck: boolean,
    isEmployment: boolean,
    isProfessionalReference: boolean,
    isBackgroundCheckStandard: boolean,
    schoolIds: string,
    licenseIds: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.post(
          `api/PSV/PSVOrderPlaced?HRMRUserId=${hrmrUserId}&isEducation=${isEducation}&IsIntEduc=${IsIntEduc}&isLicense=${isLicense}&IsBackgroundCheck=${isBackgroundCheck}&IsEmployment=${isEmployment}&IsProfessionalReference=${isProfessionalReference}&IsBackgroundCheckStandard=${isBackgroundCheckStandard}&UsCanadianSchoolIds=${schoolIds}&LicenseNumberIds=${licenseIds}`
        );
        resolve(response);
        if (response.status === 200) {
          dispatch(
            toastMessageActions.showToast({
              message: messages.orderPlacedSuccessfully,
              type: toastTypes.SUCCESS,
            })
          );
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: response.data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getAllOrderSummaryList =
  (
    pageNumber: number,
    pageSize: number,
    month: string,
    year: string,
    orderStatus: string,
    source: string,
    hrmrUserId: number,
    search?: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);
        let url = `api/PSV/OrderSummary?PageNumber=${pageNumber}&PageSize=${pageSize}&HRMRUserId=${hrmrUserId}`;
        if (search) {
          url += `&Search=${search}`;
        }

        if (month) {
          url += `&Month=${month}`;
        }

        if (year) {
          url += `&Year=${year}`;
        }

        if (orderStatus) {
          url += `&Status=${orderStatus}`;
        }

        if (source) {
          url += `&Source=${source}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(psvActions.updateOrderSummaryList(data));
          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getOrderSummaryRptExport =
  (hrmrUserId: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient.get(
          `api/PSV/OrderSummaryReport?HRMRUserId=${hrmrUserId}`
        );
        const data = response.data;
        const status = response.status;
        resolve(response);
        if (status === 200) {
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const psvOrderConfirmForCW =
  (hrmrUserId: number, activityId: number, psvt: string) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.post(
          `api/PSV/PSVOrderPlacedForCW?HRMRUserId=${hrmrUserId}&ActivityId=${activityId}&PSVT=${psvt}`
        );
        resolve(response);
        if (response.status === 200) {
          dispatch(
            toastMessageActions.showToast({
              message: messages.orderPlacedSuccessfully,
              type: toastTypes.SUCCESS,
            })
          );
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: response.data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const completePlaceOrderForCW =
  (hrsaDocId: number, orderStatus: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.post(
          `api/PSV/PSVOrderMarkCompleteForCW?HRSADocId=${hrsaDocId}&OrderStatus=${orderStatus}`
        );
        resolve(response);
        if (response.status === 200) {
          dispatch(
            toastMessageActions.showToast({
              message: messages.orderCompletedSuccessfully,
              type: toastTypes.SUCCESS,
            })
          );
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: response.data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };
