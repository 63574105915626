import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { IEmployeeRequestReportsProps } from "@helpers/uiInterfaces/employeeFiles";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import styles from "./EmployeeFiles.module.scss";
import { parseSelectControlsData } from "@utilities/index";
import moment from "moment";
import {
  getAllExclusionTypes,
  requestExclusionReport,
} from "@services/employeeFiles/EditEmployeeExclusions";
import empExclusionTypes from "@constants/emp-exclusion-types";
import empExclusionForValues from "@constants/employee-exclusion-for-values";
import { getYearOptions } from "@services/master/master.services";
import { IExclusionTypes } from "@helpers/interfaces/employeeFiles";

const EmployeeRequestReportsTab: React.FC<IEmployeeRequestReportsProps> = (
  props
) => {
  const { open, onClose } = props;
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [exclusionTypes, setExclusionTypes] = useState<Array<IExclusionTypes>>(
    []
  );
  const dispatch = useDispatch<any>();

  useEffect(() => {
    getYearOptionsData();
    getAllExclusionsTypes();
  }, []);

  const getYearOptionsData = async () => {
    const response = await dispatch(getYearOptions());
    if (response.status === 200) {
      setYearOptions(response.data);
    }
  };

  const getAllExclusionsTypes = async () => {
    const response = await dispatch(getAllExclusionTypes());
    if (response.status === 200) {
      setExclusionTypes(response.data);
    }
  };

  const handleSelectControlChange = (name: string, value: string) => {
    setSelectedYear(Number(value));
  };

  const handleBtnClick = (exclusionType: string) => {
    dispatch(
      requestExclusionReport(
        empExclusionTypes.EMPLOYEE,
        exclusionType,
        selectedYear,
        0,
        false
      )
    );
  };

  const renderbuttonControls = () => {
    const stateLicenseReportControlAttributes = {
      className: "btn btnorg w240",
      onClick() {
        handleBtnClick(empExclusionForValues.STATELICENCE_CA);
      },
    };

    return (
      <div className={styles.reqReportBtnContainer}>
        {exclusionTypes
          .filter((t) => t.exclusionType !== "California")
          .map((type) => {
            const exclusionTypeControlAttributes = {
              className: "btn btnorg w240",
              onClick() {
                handleBtnClick(type.exclusionType);
              },
            };
            if (type.isActive && empExclusionForValues[type.exclusionType]) {
              return (
                <button {...exclusionTypeControlAttributes}>
                  {empExclusionForValues[type.exclusionType]}
                </button>
              );
            }
          })}
        <button {...stateLicenseReportControlAttributes}>
          State License (CA) Report
        </button>
      </div>
    );
  };

  const renderYearDropDownSection = () => {
    const yearControlAttributes = {
      label: "Year",
      name: "year",
      value: selectedYear,
      data: parseSelectControlsData(yearOptions),
      onChange: handleSelectControlChange,
    };

    return (
      <div className={styles.yearDropdownContainer}>
        <FormSelectInputControl {...yearControlAttributes} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div id={styles.reqReportContainer}>
        {renderYearDropDownSection()}
        {renderbuttonControls()}
      </div>
    );
  };

  return (
    <Offcanvas
      show={open}
      onHide={onClose}
      placement="end"
      style={{ width: "52vw" }}
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Request Exclusion Report</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{renderContent()}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default EmployeeRequestReportsTab;
