const orderStatusList = {
  Pending: "Pending",
  ORDER_PLACED: "Order Placed",
  APP_PENDING: "x:app_pending",
  ERROR: "Error",
  ORDER_COMPLETED: "Order Completed",
  PARTIAL: "Partial",
  UPDATED: "Updated",
  CANCELED: "Canceled",
  APPLICANT_PENDING: "Applicant Pending",
  READY: "Ready",
};

export default orderStatusList;
