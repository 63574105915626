import React, { useCallback, useEffect, useState } from "react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDashboardInitialAndReCredSelectedFiltersProps } from "@helpers/uiInterfaces/employeeFiles";
import { getCheckboxSelectButtonConfigurations } from "@common/checkbox-select/utilities";
import {
  parseCredJobSelectControlsData,
  parseSelectContactsControlsData,
} from "@utilities/index";
import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import { getOrganizationContactsByType } from "@services/organizations/contact";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/index";

const DashboardInitialAndReCredSelectedFilters: React.FC<
  IDashboardInitialAndReCredSelectedFiltersProps
> = (props) => {
  const { filters, onFilterChange } = props;
  const dispatch = useDispatch<any>();
  const [credentialerOptions, setCredentialerOptions] = useState([]);
  const [analystOptions, setAnalystOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);

  const jobCredStatus = useAppSelector(
    (state) => state.trackingReport.jobCredStatus
  );

  const loadDependencies = useCallback(async () => {
    const credentialerRes = await dispatch(getOrganizationContactsByType(9));
    if (credentialerRes.status === 200)
      setCredentialerOptions(credentialerRes.data);

    const analystRes = await dispatch(getOrganizationContactsByType(10));
    if (analystRes.status === 200) setAnalystOptions(analystRes.data);

    const managerRes = await dispatch(getOrganizationContactsByType(11));
    if (managerRes.status === 200) setManagerOptions(managerRes.data);
  }, [dispatch]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  function handleFilterChange(name: string) {
    let data: any = { ...filters };
    if (name === "employeeType" || name === "employeeStatus") {
      data[name] = "All";
    } else if (
      name === "department" ||
      name === "locations" ||
      name === "credOrganizationId" ||
      name === "processingType"
    ) {
      data[name] = [];
    } else if (
      name === "associatedCredentialer" ||
      name === "assignedDataAnalyst" ||
      name === "assignedManager" ||
      name === "requestCredType" ||
      name === "credJobStatus"
    ) {
      data[name] = 0;
    } else {
      data[name] = "";
    }
    onFilterChange(data);
  }

  function renderRemoveFilterControl(name: string) {
    const removeFilterControlAttributes = {
      icon: faCircleXmark,
      className: "remove-filter-control",
      onClick() {
        handleFilterChange(name);
      },
    };

    return <FontAwesomeIcon {...removeFilterControlAttributes} />;
  }

  function renderFilterChip(label: string, name: string, value: string) {
    const filterChipLabelAttributes = {
      title: `${label}: ${value}`,
    };

    return (
      <>
        {value !== "All" && (
          <div className="filter-chip">
            <label {...filterChipLabelAttributes}>
              {label}: {value}
            </label>
            {renderRemoveFilterControl(name)}
          </div>
        )}
      </>
    );
  }

  function renderSearchNameFilter() {
    if (filters.search.trim() === "") {
      return;
    }

    return renderFilterChip("Name", "search", filters.search);
  }

  function renderEmployeeTypeFilter() {
    if (!filters.employeeType) {
      return;
    }

    let employeeTypeValue = "All";

    if (filters.employeeType === "1") {
      employeeTypeValue = "Active";
    } else if (filters.employeeType === "0") {
      employeeTypeValue = "Inactive";
    }

    return renderFilterChip("Provider Type", "employeeType", employeeTypeValue);
  }

  function renderEmployeeStatusFilter() {
    if (!filters.employeeStatus) {
      return;
    }

    return renderFilterChip(
      "Provider Status",
      "employeeStatus",
      filters.employeeStatus
    );
  }

  function renderLocationsFilter() {
    if (!filters.locations) {
      return;
    }

    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.locations);

    let locationsFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      locationsFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip("Locations", "locations", locationsFilterLabel);
  }

  function renderDepartmentFilter() {
    if (!filters.department) {
      return;
    }

    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.department);

    let departmentsFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      departmentsFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Departments",
      "department",
      departmentsFilterLabel
    );
  }

  function renderAssociatedCredentialerFilter() {
    if (!filters.associatedCredentialer) {
      return;
    }
    const value =
      parseSelectContactsControlsData(credentialerOptions).find(
        (data) => data.value === +filters.associatedCredentialer
      )?.label || "";
    return renderFilterChip(
      "Associated Credentialer",
      "associatedCredentialer",
      value
    );
  }

  function renderAssignedDataAnalystFilter() {
    if (!filters.assignedDataAnalyst) {
      return;
    }
    const value =
      parseSelectContactsControlsData(analystOptions).find(
        (data) => data.value === +filters.assignedDataAnalyst
      )?.label || "";
    return renderFilterChip(
      "Assigned Data Analyst",
      "assignedDataAnalyst",
      value
    );
  }

  function renderAssignedManagerFilter() {
    if (!filters.assignedManager) {
      return;
    }
    const value =
      parseSelectContactsControlsData(managerOptions).find(
        (data) => data.value === +filters.assignedManager
      )?.label || "";
    return renderFilterChip("Assigned Manager", "assignedManager", value);
  }

  function renderRequestCredTypeFilter() {
    if (!filters.requestCredType) {
      return;
    }
    const value =
      reqCredTypeOptions.find((data) => data.value === +filters.requestCredType)
        ?.label || "";
    return renderFilterChip("Requested Cred Type", "requestCredType", value);
  }

  function renderProcessignTypeFilter() {
    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.processingType);

    let credProcessignTypeFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      credProcessignTypeFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Processing Type",
      "processingType",
      credProcessignTypeFilterLabel
    );
  }

  function renderCredStatusFilter() {
    if (!filters.credJobStatus) {
      return;
    }
    const value =
      parseCredJobSelectControlsData(jobCredStatus).find(
        (data) => data.value === +filters.credJobStatus
      )?.label || "";
    return renderFilterChip("Cred/Instance Status", "credJobStatus", value);
  }

  function renderCredOrgFilter() {
    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.credOrganizationId);

    let credOrgFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      credOrgFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Cred Organization",
      "credOrganizationId",
      credOrgFilterLabel
    );
  }

  function renderStartDateFilter() {
    if (!filters.startDate) {
      return;
    }

    return renderFilterChip("Start Date", "startDate", filters.startDate);
  }

  function renderEndDateFilter() {
    if (!filters.endDate) {
      return;
    }

    return renderFilterChip("End Date", "endDate", filters.endDate);
  }

  return (
    <div className="selected-filters">
      {renderEmployeeTypeFilter()}
      {renderLocationsFilter()}
      {renderEmployeeStatusFilter()}
      {renderDepartmentFilter()}
      {renderSearchNameFilter()}
      {renderAssociatedCredentialerFilter()}
      {renderAssignedDataAnalystFilter()}
      {renderAssignedManagerFilter()}
      {renderRequestCredTypeFilter()}
      {renderProcessignTypeFilter()}
      {renderCredStatusFilter()}
      {renderCredOrgFilter()}
      {renderStartDateFilter()}
      {renderEndDateFilter()}
    </div>
  );
};

export default DashboardInitialAndReCredSelectedFilters;
