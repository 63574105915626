import React from "react";
import routesNames from "@constants/routes-names";
import { IRoute } from "helpers/uiInterfaces/common";
import DocuSignReport from "@pages/reports/DocuSignReport/DocuSignReport";
import PSV from "@pages/PSV/PSV";
import OrderSummary from "@pages/OrderSummary/OrderSummary";
import DashboardInitialAndReCred from "@pages/dashboardInitialAndReCred/DashboardInitialAndReCred";
import ImportExport from "@pages/importExclusion/ImportExclusionData";
import RequestCredentialing from "@pages/RequestCredentialing/RequestCredentialing";
import Reporting from "@pages/Reporting/Reporting";

const Dashboard = React.lazy(() => import("@pages/Dashboard"));
const ThankyouPage = React.lazy(
  () => import("@common/Thankyou-component/ThankyouPage")
);
const ErrorPage = React.lazy(() => import("@common/Error-component/ErrorPage"));
const Authenticate = React.lazy(
  () => import("@pages/authenticate/Authenticate")
);
const CredentialingProfile = React.lazy(
  () => import("@pages/credentialingProfile/Credentialing")
);
const NPDB = React.lazy(() => import("@pages/NPDB/NPDB"));
const ActivityChecklist = React.lazy(
  () => import("@pages/activityChecklist/ActivityChecklist")
);
const DepartmentList = React.lazy(
  () => import("@pages/departments/DepartmentList")
);
const ManageDocuments = React.lazy(
  () => import("@pages/manageDocuments/ManageDocuments")
);
const ServiceSites = React.lazy(
  () => import("@pages/serviceSites/ServiceSites")
);
const OrganizationalContacts = React.lazy(
  () => import("@pages/organizationalContacts/OrganizationalContacts")
);
const TrainingLibrary = React.lazy(
  () => import("@pages/trainingLibrary/TrainingLibrary")
);
const TrainingReport = React.lazy(
  () => import("@pages/trainingReport/TrainingReport")
);
const TrainingDetailsReport = React.lazy(
  () => import("@pages/trainingReport/TrainingDetailsReport")
);
const Expirables = React.lazy(() => import("@pages/expirables/Expirables"));
const ManageProfile = React.lazy(
  () => import("@pages/manageProfile/ManageProfile")
);
const EmployeeCredentialingProfile = React.lazy(
  () =>
    import("@pages/employeeCredentialingProfile/EmployeeCredentialingProfile")
);
const EmployeeFiles = React.lazy(
  () => import("@pages/employeeFiles/EmployeeFiles")
);
const ClinicalStaff = React.lazy(
  () => import("@pages/clinicalStaff/ClinicalStaff")
);
const NonClinicalStaff = React.lazy(
  () => import("@pages/nonClinicalStaff/NonClinicalStaff")
);
const SetupIntegrations = React.lazy(
  () => import("@pages/setupIntegrations/SetupIntegrations")
);
const Facilities = React.lazy(() => import("@pages/facilities/Facilities"));

const initialRoutes: Array<IRoute> = [
  {
    path: routesNames.dashboardRoute,
    breadcrumb: "Dashboard",
    element: <Dashboard />,
    requiresAuth: true,
  },
  {
    path: routesNames.authenticateRoute,
    breadcrumb: "Authenticate",
    element: <Authenticate />,
    requiresAuth: false,
  },
  {
    path: routesNames.thankyouPageRoute,
    breadcrumb: "Thankyou",
    element: <ThankyouPage />,
    requiresAuth: false,
  },
  {
    path: routesNames.errorPageRoute,
    breadcrumb: "Error",
    element: <ErrorPage />,
    requiresAuth: false,
  },
  {
    path: routesNames.credentialingProfileRoute,
    breadcrumb: "Credentialing Profile",
    element: <CredentialingProfile />,
    requiresAuth: true,
  },
  {
    path: routesNames.npdbRoute,
    breadcrumb: "NPDB",
    element: <NPDB />,
    requiresAuth: true,
  },
  {
    path: routesNames.psvRoute,
    breadcrumb: "PSV",
    element: <PSV />,
    requiresAuth: true,
  },
  {
    path: routesNames.orderSummary,
    breadcrumb: "Order Summary",
    element: <OrderSummary />,
    requiresAuth: true,
  },
  {
    path: routesNames.activityChecklistRoute,
    breadcrumb: "Activity Checklist",
    element: <ActivityChecklist />,
    requiresAuth: true,
  },
  {
    path: routesNames.departmentsRoute,
    breadcrumb: "Departments",
    element: <DepartmentList />,
    requiresAuth: true,
  },
  {
    path: routesNames.manageDocumentsRoute,
    breadcrumb: "Manage Documents",
    element: <ManageDocuments />,
    requiresAuth: true,
  },
  {
    path: routesNames.serviceSitesRoute,
    breadcrumb: "Service Sites",
    element: <ServiceSites />,
    requiresAuth: true,
  },
  {
    path: routesNames.organizationalContactsRoute,
    breadcrumb: "Organizational Contacts",
    element: <OrganizationalContacts />,
    requiresAuth: true,
  },
  {
    path: routesNames.trainingLibraryRoute,
    breadcrumb: "Training Library",
    element: <TrainingLibrary />,
    requiresAuth: true,
  },
  {
    path: routesNames.trainingReportRoute,
    breadcrumb: "Training Report",
    element: <TrainingReport />,
    requiresAuth: true,
  },
  {
    path: routesNames.trainingDetailsReportRoute,
    breadcrumb: "Details",
    element: <TrainingDetailsReport />,
    requiresAuth: true,
  },
  {
    path: routesNames.expirablesRoute,
    breadcrumb: "Expirables",
    element: <Expirables />,
    requiresAuth: true,
  },
  {
    path: routesNames.manageProfileRoute,
    breadcrumb: "Manage Profile",
    element: <ManageProfile />,
    requiresAuth: true,
  },
  {
    path: routesNames.employeeFiles,
    breadcrumb: "Provider Files",
    element: <EmployeeFiles />,
    requiresAuth: true,
  },
  {
    path: routesNames.employeeCredentialingProfileRoute,
    breadcrumb: "Provider Credentialing Profile",
    element: <EmployeeCredentialingProfile />,
    requiresAuth: true,
  },
  {
    path: routesNames.docuSignReport,
    breadcrumb: "DocuSign Report",
    element: <DocuSignReport />,
    requiresAuth: true,
  },
  {
    path: routesNames.clinicalStaff,
    breadcrumb: "Licensed Providers",
    element: <ClinicalStaff />,
    requiresAuth: true,
  },
  {
    path: routesNames.nonClinicalStaff,
    breadcrumb: "Non Licensed Staff",
    element: <NonClinicalStaff />,
    requiresAuth: true,
  },
  {
    path: routesNames.facilities,
    breadcrumb: "Facilities",
    element: <Facilities />,
    requiresAuth: true,
  },
  {
    path: routesNames.setupIntegrationsRoute,
    breadcrumb: "Setup Integrations",
    element: <SetupIntegrations />,
    requiresAuth: true,
  },
  {
    path: routesNames.dashboardInitialRoute,
    breadcrumb: "Dashboard Initial",
    element: <DashboardInitialAndReCred />,
    requiresAuth: true,
  },
  {
    path: routesNames.dashboardReCredRoute,
    breadcrumb: "Dashboard Re-Cred",
    element: <DashboardInitialAndReCred />,
    requiresAuth: true,
  },
  {
    path: routesNames.reportingRoute,
    breadcrumb: "Reports",
    element: <Reporting />,
    requiresAuth: true,
  },
  {
    path: routesNames.importExclusionData,
    breadcrumb: "Import Exclusion Data",
    element: <ImportExport />,
    requiresAuth: true,
  },
  {
    path: routesNames.reqToCredentialing,
    breadcrumb: "Request Credentialing",
    element: <RequestCredentialing />,
    requiresAuth: true,
  },
];

export default initialRoutes;
