import React, { useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAsterisk,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

import useOnClickOutside from "@hooks/useOnClickOutside";

import {
  ICheckboxSelectDataStructure,
  ICheckboxSelectProps,
} from "@helpers/uiInterfaces/common";

import {
  getCheckboxSelectButtonConfigurations,
  handleItemSelectDataParsing,
  handleSelectAllDataParsing,
} from "./utilities";

import styles from "./CheckboxSelect.module.scss";

const CheckboxSelect: React.FC<ICheckboxSelectProps> = (props) => {
  const { label, data, required, error, onSelect } = props;

  const checkboxSelectControlsContainerReference = useRef(null);

  const [displayOptionsList, setDisplayOptionsList] = useState(false);

  const { startingFourSelectedValues, selectedItemsCount, totalItemsCount } =
    getCheckboxSelectButtonConfigurations(data);

  useOnClickOutside(
    checkboxSelectControlsContainerReference,
    closeOptionsListPopup
  );

  function closeOptionsListPopup() {
    setDisplayOptionsList(false);
  }

  function renderChevronIcon() {
    const chevronIconAttributes = {
      className: styles.chevronIcon,
    };

    if (displayOptionsList === false) {
      return <FontAwesomeIcon icon={faCaretDown} {...chevronIconAttributes} />;
    }

    return <FontAwesomeIcon icon={faCaretUp} {...chevronIconAttributes} />;
  }

  function renderErrorMessage() {
    if (!error) {
      return;
    }

    return <label className={styles.errorMessage}>{error}</label>;
  }

  function renderAsterisk() {
    if (!required) {
      return;
    }

    return (
      <FontAwesomeIcon icon={faAsterisk} className={styles.requiredAsterisk} />
    );
  }

  function renderButtonControl() {
    let buttonLabel = startingFourSelectedValues;

    if (selectedItemsCount === 0) {
      buttonLabel = "";
    }

    if (selectedItemsCount > 4) {
      buttonLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    if (selectedItemsCount === totalItemsCount) {
      buttonLabel = "-- All --";
    }

    const buttonControlAttributes = {
      className: styles.checkboxSelectButton,
      onClick() {
        setDisplayOptionsList((_displayOptionsList) => !displayOptionsList);
      },
    };

    return (
      <button {...buttonControlAttributes}>
        <label className={styles.buttonLabel}>{buttonLabel}</label>
        {renderChevronIcon()}
      </button>
    );
  }

  function renderOptionItem(item: ICheckboxSelectDataStructure, index: number) {
    const optionItemAttributes = {
      className: styles.optionItem,
      key: index,
      onClick() {
        const parsedData = handleItemSelectDataParsing(data, index);
        onSelect(parsedData);
      },
    };

    const checkboxControlAttributes = {
      className: "form-check-input me-2",
      type: "checkbox",
      checked: item.selected,
    };

    return (
      <div {...optionItemAttributes}>
        <input {...checkboxControlAttributes} />
        <label className={styles.optionItemLabel}>{item.label}</label>
      </div>
    );
  }

  function renderSelectAllOption() {
    const defaultSelectAllValue =
      selectedItemsCount === totalItemsCount ? true : false;

    const optionItemAttributes = {
      className: styles.optionItem,
      onClick() {
        const parsedData = handleSelectAllDataParsing(
          data,
          defaultSelectAllValue
        );
        onSelect(parsedData);
      },
    };

    const checkboxControlAttributes = {
      className: "form-check-input me-2",
      type: "checkbox",
      checked: defaultSelectAllValue,
    };

    return (
      <div {...optionItemAttributes}>
        <input {...checkboxControlAttributes} />
        <label className={styles.optionItemLabel}>Select All</label>
      </div>
    );
  }

  function renderOptionsList() {
    if (displayOptionsList === false) {
      return;
    }

    if (data.length === 0) {
      return (
        <div className={styles.optionsListContainer}>
          <label className={styles.noResultFoundMessage}>
            No result found for your search.
          </label>
        </div>
      );
    }

    return (
      <div className={styles.optionsListContainer}>
        {renderSelectAllOption()}
        {data.map((item, index) => renderOptionItem(item, index))}
      </div>
    );
  }

  const checkboxSelectControlsContainerAttributes = {
    className: styles.checkboxSelectControlsContainer,
    ref: checkboxSelectControlsContainerReference,
  };

  return (
    <div className={styles.checkboxSelectMain}>
      <label className={styles.label}>
        {renderAsterisk()}
        {label}
      </label>
      <div {...checkboxSelectControlsContainerAttributes}>
        {renderButtonControl()}
        {renderOptionsList()}
      </div>
      {renderErrorMessage()}
    </div>
  );
};

export default CheckboxSelect;
