import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IEmpPayerPrivilegingList,
  IPrivilegingHistoryModel,
  IPrivilegingLogsModel,
  IPrivilegingModel,
  IPrivilegingSpecialityPaginationModel,
} from "@helpers/interfaces/employeeFiles";
import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";

const initialState = {
  employeePayerPrivilegingList: [] as Array<IEmpPayerPrivilegingList>,
  privilegingPayerProviderData: {} as IPrivilegingModel,
  privilegingPayerProviderDataLogs: [] as Array<IPrivilegingLogsModel>,
  privilegingCategoryList: [] as Array<IMastersDataModel>,
  privilegingCategoryByRequestPrivIds:
    {} as IPrivilegingSpecialityPaginationModel,
  privilegingReqCatesByLastPayerProviderId:
    [] as Array<IPrivilegingHistoryModel>,
  error: null as any,
  loading: false,
  logsLoading: false,
  categoryLoading: false,
};

const editEmployeesPayerSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateEmployeePayerPrivilegingList: (
      state,
      action: PayloadAction<Array<IEmpPayerPrivilegingList>>
    ) => {
      state.employeePayerPrivilegingList = action.payload;
    },

    updatePrivilegingPayerProviderById: (
      state,
      action: PayloadAction<IPrivilegingModel>
    ) => {
      state.privilegingPayerProviderData = action.payload;
    },

    updatePrivilegingPayerProviderLogsById: (
      state,
      action: PayloadAction<Array<IPrivilegingLogsModel>>
    ) => {
      state.privilegingPayerProviderDataLogs = action.payload;
    },

    updatePrivilegingCategories: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.privilegingCategoryList = action.payload;
    },

    updatePrivilegingCategoryByRequestPrivIds: (
      state,
      action: PayloadAction<IPrivilegingSpecialityPaginationModel>
    ) => {
      state.privilegingCategoryByRequestPrivIds = action.payload;
    },

    updatePrivilegingReqCatesByLastPayerProviderId: (
      state,
      action: PayloadAction<Array<IPrivilegingHistoryModel>>
    ) => {
      state.privilegingReqCatesByLastPayerProviderId = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.logsLoading = action.payload;
    },

    updateCategoryLoading: (state, action: PayloadAction<boolean>) => {
      state.categoryLoading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.employeePayerPrivilegingList =
        [] as Array<IEmpPayerPrivilegingList>;
      state.privilegingPayerProviderData = {} as IPrivilegingModel;
      state.privilegingPayerProviderDataLogs =
        [] as Array<IPrivilegingLogsModel>;
      state.privilegingCategoryList = [] as Array<IMastersDataModel>;
      state.privilegingCategoryByRequestPrivIds =
        {} as IPrivilegingSpecialityPaginationModel;
      state.privilegingReqCatesByLastPayerProviderId =
        [] as Array<IPrivilegingHistoryModel>;
      state.logsLoading = false;
      state.categoryLoading = false;
    },
  },
});

export const editEmployeesPayerActions = editEmployeesPayerSlice.actions;
export default editEmployeesPayerSlice.reducer;
