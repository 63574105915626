import { IDepartmentModel } from "@helpers/interfaces/departments/Department";
import { IDashboardInitialAndReCredDataModel } from "@helpers/interfaces/employeeFiles";
import {
  IMastersDataModel,
  IOrganizationModel,
} from "@helpers/interfaces/masters/Masters";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";
import moment from "moment";

export function parseDashboardInitialAndReCredList(
  dashboardInitialAndReCredList: Array<IDashboardInitialAndReCredDataModel>,
  renderEmployeeProfilePicture: (
    dashboardInitialAndReCredData: IDashboardInitialAndReCredDataModel
  ) => void,
  renderAlertControl: (
    dashboardInitialAndReCredData: IDashboardInitialAndReCredDataModel
  ) => void,
  renderSendReminderControl: (
    dashboardInitialAndReCredData: IDashboardInitialAndReCredDataModel
  ) => void
) {
  const _dashboardInitialAndReCredDataList = [];

  for (let dashboardInitialAndReCredData of dashboardInitialAndReCredList) {
    const _dashboardInitialAndReCredData = {
      ...dashboardInitialAndReCredData,
      createDate: dashboardInitialAndReCredData.createdDate
        ? moment(dashboardInitialAndReCredData.createdDate).format("MM/DD/YYYY")
        : "",
      renderEmployeeProfilePicture: renderEmployeeProfilePicture(
        dashboardInitialAndReCredData
      ),
      renderAlertsControl: renderAlertControl(dashboardInitialAndReCredData),
      sendReminderControl: renderSendReminderControl(
        dashboardInitialAndReCredData
      ),
    };

    _dashboardInitialAndReCredDataList.push(_dashboardInitialAndReCredData);
  }

  return _dashboardInitialAndReCredDataList;
}

export function parseEmployeeListFiltersLocationData(
  locations: Array<IMastersDataModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _locations: Array<ICheckboxSelectDataStructure> = [];

  for (let location of locations) {
    const object: ICheckboxSelectDataStructure = {
      label: location.name,
      value: String(location.id),
      selected: true,
    };

    _locations.push(object);
  }

  return _locations;
}

export function parseEmployeeListFiltersDepartmentData(
  departments: Array<IDepartmentModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _departments: Array<ICheckboxSelectDataStructure> = [];

  for (let department of departments) {
    const object: ICheckboxSelectDataStructure = {
      label: department.departmentName,
      value: String(department.departmentId),
      selected: true,
    };

    _departments.push(object);
  }

  return _departments;
}

export function parseDashboardInitialReCredListFiltersCredOrganizationData(
  organizations: Array<IOrganizationModel>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _organizations: Array<ICheckboxSelectDataStructure> = [];

  for (let organization of organizations) {
    const object: ICheckboxSelectDataStructure = {
      label: organization.organizationName,
      value: String(organization.organizationId),
      selected: true,
    };

    _organizations.push(object);
  }

  return _organizations;
}

export const parseDashboardInitialReCredListFiltersProcessingTypeData = (
  processingTypes: Array<any>,
  checkboxDataList: Array<ICheckboxSelectDataStructure>
) => {
  if (checkboxDataList.length !== 0) {
    return checkboxDataList;
  }

  const _processingTypes: Array<ICheckboxSelectDataStructure> = [];

  for (let processingType of processingTypes) {
    const object: ICheckboxSelectDataStructure = {
      label: processingType.label,
      value: String(processingType.value),
      selected: true,
    };

    _processingTypes.push(object);
  }

  return _processingTypes;
};
