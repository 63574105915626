const empExclusionForValues: Record<any, string> = {
  OIG: "OIG Exclusion Report",
  SAM: "SAM Exclusion Report",
  Texas: "Texas Medicaid Exclusion Report",
  OFAC: "OFAC Exclusion Report",
  Michigan: "Michigan Exclusions",
  CALIFORNIA: "California",
  STATELICENCE_CA: "STATELICENCE_CA",
  MedicalFeeForService: "Medi-Cal Fee For Service Report",
  MedicareOptOut: "Medicare Opt Out Report",
  MedicalProcedureCodeAndLimitationCode: "Medi-Cal Suspended Report",
  Newyork: "New York Exclusions Report",
  Iowa: "Iowa Exclusions Report",
  SouthDakota: "South Dakota Exclusions Report",
  NorthDakota: "North Dakota Exclusions Report",
  Minnesota: "Minnesota Exclusions Report",
  Nebraska: "Nebraska Exclusions Report",
};

export default empExclusionForValues;
