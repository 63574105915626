import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IStaffPaginationModel } from "@helpers/interfaces/clinicalAndNonClinicalstaff";

const initialState = {
  staffList: {} as IStaffPaginationModel,
  searchState: {} as any,
  preserveRoute: false,
  error: null as any,
  loading: false,
};

const StaffSlice = createSlice({
  name: "clinicalAndNonClinicalStaff",
  initialState,
  reducers: {
    updateStaffList: (state, action: PayloadAction<IStaffPaginationModel>) => {
      state.staffList = action.payload;
    },

    updateSearchState: (state, action: PayloadAction<any>) => {
      state.searchState = action.payload;
    },

    updatePreserveRoute: (state, action: PayloadAction<any>) => {
      state.preserveRoute = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const staffActions = StaffSlice.actions;
export default StaffSlice.reducer;
